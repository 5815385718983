import React, { useEffect } from "react";

import _get from "lodash/get";
import classNames from "classnames";

import { getClassName } from "../../../0-electrons/css";

import * as css from "../Body.module.scss";

// export type BodyPartsActive = {
//   anteriorTrunk: { front: false; back: false };
//   armLeft: { front: false; back: false };
//   armRight: { front: false; back: false };
//   genitals: false;
//   handLeft: false;
//   handRight: false;
//   headAndNeck: { front: false; back: false };
//   legLeft: { front: false; back: false };
//   legRight: { front: false; back: false };
// };

export const BodySvg = ({ onClick, active, type }) => {
  const svgOnClick = e => {
    const target = e.target || e.nativeEvent.target;
    onClick(target.getAttribute("aria-label"));
  };
  const cssClasses = {
    base: getClassName(css, "BodySvg"),
    ground: getClassName(css, "BodySvg__ground"),
    area: getClassName(css, "BodySvg__area"),
    areaActive: getClassName(css, "BodySvg__area--active"),
  };
  const getAreaClassName = area => {
    return classNames(cssClasses.area, {
      [cssClasses.areaActive as string]: _get(active, area, false),
    });
  };
  const baseArgs = {
    className: cssClasses.base,
    onClick: svgOnClick,
  };

  if (type === "AdultBack") {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox="0 0 205 458"
        {...baseArgs}
      >
        <path
          className={cssClasses.ground}
          d="M46.2 432.7c-4.5-13.2 57.7-18.1 84.7-9.2 12.9 1 27.1 3.8 27.1 9.2 0 2.9 4.5 9.2 0 11.2-4 5.5-1.3 4.9-15.9 7.5-8.2 4.8-13.3 5.6-27.9 5.6-32.4-.2-61.5-5.6-68-24.3"
        />
        <path
          aria-label="headAndNeck.back"
          className={getAreaClassName("headAndNeck.back")}
          d="M84 38.5c.7 2.5 3.5 7.1 5.6 9.5 3.5 3.5 8.4 5 13.2 5h.4c4.7 0 9.7-1.5 13.2-5 2.2-2.4 4.9-7 5.7-9.5.9.2 2.4-2 3.3-5 .9-3 .9-5.7 0-6-.2-.1-.5 0-.7.2 1-10.1-1.8-17.2-8.7-22.8-.7-.6-1.5-1.2-2.3-1.7l-.1-.1c-3.1-1.8-6.6-3-10.3-3.1h-.6c-3.7.1-7.2 1.3-10.2 3.2l-.1.1c-.8.5-1.6 1.1-2.3 1.7-6.9 5.6-9.8 12.7-8.7 22.8-.3-.2-.5-.3-.7-.2-.9.3-.9 3 0 6 .9 2.9 2.4 5.1 3.3 4.9"
        />

        <path
          aria-label="armLeftUpper.back"
          className={getAreaClassName("armLeftUpper.back")}
          d="M65.3590809,75.218 L65.3590809,106.286 C65.3590809,106.541 65.3130809,106.788 65.2130809,107.016 C64.3180809,109.024 59.8270809,119.222 58.1010809,123.513 C58.0100809,123.741 57.8910809,124.033 57.7450809,124.371 L37.7880809,118.848 C38.5820809,116.337 39.3770809,113.817 40.1890809,111.234 L40.3080809,111.261 C42.7270809,103.629 45.4020809,95.422 49.0540809,85.352 C52.3680809,75.912 57.4070809,76.167 61.3970809,75.063 C61.9900809,74.908 63.2780809,74.57 64.2270809,74.332 C64.8020809,74.186 65.3590809,74.615 65.3590809,75.218"
        />
        <path
          aria-label="armLeftMiddle.back"
          className={getAreaClassName("armLeftMiddle.back")}
          d="M57.0056809,126.0965 C53.7916809,133.5185 45.6946809,151.4945 43.3476809,158.2505 C43.3476809,158.2505 43.0096809,159.4645 42.4626809,161.3635 L25.2446809,156.6075 C28.0376809,149.5865 31.5346809,137.7365 34.2826809,129.6295 C35.3046809,126.5625 36.2726809,123.5955 37.2226809,120.6275 L57.0056809,126.0965 Z"
        />
        <path
          aria-label="armLeftLower.back"
          className={getAreaClassName("armLeftLower.back")}
          d="M41.9511809,163.153 C40.3441809,168.713 37.7331809,177.55 36.1721809,181.887 C33.4971809,189.282 28.2481809,202.09 26.2301809,208.389 L15.3481809,205.386 C15.3661809,205.285 15.3931809,205.185 15.4121809,205.085 C16.2521809,200.684 16.8181809,195.928 17.0821809,193.052 C17.4661809,188.788 19.1461809,176.993 20.1861809,172.292 C20.9531809,168.813 23.7381809,160.734 24.5681809,158.351 L41.9511809,163.153 Z"
        />
        <path
          aria-label="handLeft.back"
          className={getAreaClassName("handLeft.back")}
          d="M25.2625809,211.886 C25.3725809,213.338 28.0565809,215.191 28.0565809,218.77 C28.0565809,222.011 27.2525809,226.566 26.5775809,229.113 C25.8925809,231.743 23.4735809,239.484 21.6835809,240.671 C21.3095809,240.918 19.9765809,241.319 19.4835809,240.616 C17.9585809,244.14 16.1055809,244.423 15.0105809,243.62 C14.6995809,244.104 13.0015809,244.816 12.0245809,244.569 C11.0755809,244.332 10.3445809,242.826 10.3085809,242.251 C9.38658093,243.054 7.55158093,242.433 7.28658093,241.21 C6.80258093,238.946 7.25058093,233.979 7.79758093,229.241 C8.01658093,227.36 8.47358093,224.001 9.03058093,222.321 C9.03958093,222.294 9.27658093,221.518 8.95758093,221.518 C8.53758093,221.426 6.22758093,223.809 5.14158093,224.685 C3.32458093,226.146 -1.00241907,225.087 0.211580929,223.928 C1.42558093,222.777 5.82558093,216.25 7.85258093,214.36 C9.45958093,212.863 13.0015809,211.311 14.2705809,209.868 L14.4715809,209.366 C14.5715809,208.974 14.6725809,208.563 14.7725809,208.143 C14.8005809,208.024 14.8645809,207.705 14.9735809,207.212 L25.7185809,210.188 C25.5825809,210.681 25.4355809,211.183 25.3445809,211.557 C25.3175809,211.667 25.2895809,211.776 25.2625809,211.886"
        />

        <path
          aria-label="armRightUpper.back"
          className={getAreaClassName("armRightUpper.back")}
          d="M166.897081,118.8385 L146.931081,124.3615 C146.794081,124.0245 146.675081,123.7405 146.584081,123.5125 C144.858081,119.2215 140.367081,109.0245 139.481081,107.0155 C139.372081,106.7875 139.326081,106.5415 139.326081,106.2855 L139.326081,75.2175 C139.326081,74.6155 139.883081,74.1865 140.458081,74.3325 C141.407081,74.5695 142.704081,74.9075 143.288081,75.0625 C147.278081,76.1675 152.317081,75.9115 155.631081,85.3515 C159.283081,95.4215 161.958081,103.6285 164.377081,111.2615 L164.496081,111.2335 C165.308081,113.8085 166.103081,116.3275 166.897081,118.8385"
        />
        <path
          aria-label="armRightMiddle.back"
          className={getAreaClassName("armRightMiddle.back")}
          d="M179.440881,156.598 L162.222881,161.354 C161.674881,159.455 161.336881,158.25 161.336881,158.25 C158.990881,151.485 150.892881,133.5 147.670881,126.087 L167.462881,120.619 C168.412881,123.586 169.379881,126.562 170.402881,129.63 C173.150881,137.736 176.637881,149.577 179.440881,156.598"
        />
        <path
          aria-label="armRightLower.back"
          className={getAreaClassName("armRightLower.back")}
          d="M178.454981,208.3894 C176.436981,202.0904 171.187981,189.2814 168.512981,181.8864 C166.942981,177.5504 164.340981,168.7034 162.733981,163.1434 L180.116981,158.3414 C180.937981,160.7154 183.731981,168.8044 184.498981,172.2914 C185.538981,176.9934 187.218981,188.7884 187.602981,193.0524 C187.866981,195.9274 188.432981,200.6844 189.272981,205.0844 C189.291981,205.1854 189.309981,205.2854 189.336981,205.3764 L178.454981,208.3894 Z"
        />
        <path
          aria-label="handRight.back"
          className={getAreaClassName("handRight.back")}
          d="M199.543981,224.6855 C198.457981,223.8095 196.147981,221.4265 195.727981,221.5175 C195.407981,221.5175 195.645981,222.2935 195.654981,222.3215 C196.211981,224.0005 196.667981,227.3605 196.886981,229.2415 C197.434981,233.9795 197.881981,238.9455 197.398981,241.2095 C197.133981,242.4335 195.298981,243.0535 194.376981,242.2505 C194.339981,242.8255 193.609981,244.3325 192.659981,244.5695 C191.683981,244.8155 189.984981,244.1035 189.674981,243.6205 C188.579981,244.4235 186.725981,244.1405 185.201981,240.6165 C184.717981,241.3195 183.375981,240.9175 183.010981,240.6715 C181.220981,239.4845 178.792981,231.7425 178.107981,229.1135 C177.440981,226.5665 176.628981,222.0105 176.628981,218.7695 C176.628981,215.1905 179.312981,213.3375 179.422981,211.8865 C179.394981,211.7765 179.367981,211.6665 179.339981,211.5575 C179.248981,211.1835 179.102981,210.6715 178.965981,210.1785 L189.711981,207.2025 C189.811981,207.6955 189.884981,208.0245 189.911981,208.1425 C190.012981,208.5625 190.112981,208.9735 190.213981,209.3665 L190.414981,209.8685 C191.683981,211.3105 195.225981,212.8625 196.841981,214.3605 C198.858981,216.2495 203.259981,222.7775 204.473981,223.9275 C205.687981,225.0875 201.360981,226.1465 199.543981,224.6855"
        />

        <path
          aria-label="anteriorTrunkUpper.back"
          className={getAreaClassName("anteriorTrunkUpper.back")}
          d="M137.326681,89.1678 L67.4586809,89.1678 L67.4586809,74.1868 C67.4586809,73.7758 67.7326809,73.4198 68.1256809,73.3098 L73.6116809,71.7578 C84.7136809,69.3108 89.0136809,61.6608 89.0046809,51.4448 C89.0046809,51.0618 89.4336809,50.8518 89.7346809,51.0798 C93.0766809,53.5628 97.4856809,54.9598 102.196681,54.9598 L102.597681,54.9598 C107.299681,54.9598 111.709681,53.5628 115.050681,51.0798 C115.351681,50.8518 115.780681,51.0618 115.780681,51.4448 C115.771681,61.6608 120.071681,69.3108 131.173681,71.7578 L136.660681,73.3098 C137.052681,73.4198 137.326681,73.7758 137.326681,74.1868 L137.326681,89.1678 Z"
        />
        <path
          aria-label="anteriorTrunkMiddle.back"
          className={getAreaClassName("anteriorTrunkMiddle.back")}
          d="M68.3352809,149.3035 C68.0612809,140.3565 67.4592809,121.0295 67.4592809,121.0295 L67.4592809,91.0305 L137.326281,91.0305 L137.326281,121.0295 C137.326281,121.0295 136.724281,140.3565 136.450281,149.3035 L136.322281,149.3035 C136.267281,151.4305 136.295281,155.9585 136.313281,158.5335 L68.4632809,158.5335 C68.4902809,155.9585 68.5182809,151.4305 68.4632809,149.3035 L68.3352809,149.3035 Z"
        />
        <path
          aria-label="anteriorTrunkLower.back"
          className={getAreaClassName("anteriorTrunkLower.back")}
          d="M141.937081,202.291 C141.937081,202.793 141.526081,203.204 141.024081,203.204 L63.7610809,203.204 C63.2590809,203.204 62.8480809,202.793 62.8480809,202.291 C62.8850809,197.525 62.7480809,191.345 62.9210809,190.076 C62.9850809,189.592 63.2680809,187.748 63.4140809,186.807 L63.4050809,186.807 C63.4240809,186.707 63.4420809,186.607 63.4600809,186.506 C63.4690809,186.47 63.4690809,186.424 63.4780809,186.397 C63.5700809,185.876 63.6520809,185.347 63.7520809,184.781 C63.7610809,184.753 63.7700809,184.708 63.7700809,184.671 C63.8440809,184.306 63.9080809,183.941 63.9710809,183.566 L63.9810809,183.566 C65.4140809,175.733 67.6690809,165.545 68.4540809,160.396 L136.332081,160.396 C136.724081,162.97 137.482081,166.796 138.322081,170.959 C139.162081,175.131 140.093081,179.65 140.814081,183.566 C140.878081,183.941 140.951081,184.306 141.015081,184.671 C141.024081,184.708 141.024081,184.753 141.033081,184.781 C141.097081,185.137 141.152081,185.465 141.207081,185.803 C141.216081,185.849 141.225081,185.913 141.243081,185.986 C141.289081,186.26 141.334081,186.543 141.380081,186.807 L141.362081,186.807 C141.544081,187.967 141.809081,189.665 141.864081,190.076 C142.037081,191.345 141.910081,197.525 141.937081,202.291"
        />

        <path
          aria-label="legLeftUpper.back"
          className={getAreaClassName("legLeftUpper.back")}
          d="M100.817981,227.1231 C100.817981,227.1231 100.616981,231.0491 100.461981,232.3451 C99.5579809,240.0501 97.6959809,248.6961 96.8559809,255.3421 C96.0799809,261.5871 95.1849809,266.8541 94.4819809,272.2231 L61.4609809,272.2231 C61.2239809,270.7621 61.0229809,269.5111 60.8669809,268.5621 C60.3109809,263.9881 59.3519809,235.1571 59.6529809,230.6931 C59.6529809,230.6931 60.4479809,218.8791 60.8309809,217.0351 C61.2139809,215.1821 61.8539809,210.0511 62.4739809,207.1931 C62.5659809,206.7551 62.6569809,206.4451 62.7479809,205.8061 C62.8029809,205.3581 63.1959809,205.0211 63.6519809,205.0211 L100.050981,205.0211 C100.561981,205.0211 100.963981,205.4221 100.963981,205.9341 L100.990981,224.2651 C100.990981,224.5031 100.899981,224.7311 100.744981,224.8951 C90.7569809,235.6771 75.7759809,227.1781 72.9459809,225.4071 C72.6719809,225.2421 72.3249809,225.3151 72.1509809,225.5801 C71.9599809,225.8541 72.0329809,226.2371 72.3159809,226.4111 C74.0779809,227.5061 80.0939809,230.9391 86.9049809,231.4051 C91.3779809,231.7061 96.1889809,230.6931 100.361981,226.9041 C100.543981,226.7401 100.835981,226.8761 100.817981,227.1231"
        />
        <path
          aria-label="legLeftMiddle.back"
          className={getAreaClassName("legLeftMiddle.back")}
          d="M94.2539809,274.0851 C94.0619809,275.6551 93.8979809,277.2441 93.7519809,278.8781 L93.7239809,278.8781 C93.5239809,281.2331 92.9579809,283.6891 92.7479809,286.3281 C92.6839809,288.6191 92.5379809,298.9721 92.4649809,301.3551 C92.3919809,303.7371 92.2639809,308.1651 92.1179809,310.2921 C91.9719809,312.4101 90.7849809,318.6461 90.7849809,318.6461 C90.7849809,318.6461 90.0729809,326.7071 89.6069809,334.8601 C89.6799809,338.4291 89.6159809,341.7071 89.4609809,344.7841 L62.2639809,344.7841 C62.2919809,342.3001 62.3559809,340.3561 62.4099809,339.5981 C62.5469809,337.5621 63.8989809,325.1921 64.4189809,321.2201 C64.9479809,317.2401 65.0489809,305.5631 65.0209809,303.5091 C64.8479809,297.8951 63.6339809,285.8441 62.1909809,276.7421 C62.0359809,275.8201 61.8899809,274.9251 61.7619809,274.0851 L94.2539809,274.0851 Z"
        />
        <path
          aria-label="legLeftLower.back"
          className={getAreaClassName("legLeftLower.back")}
          d="M62.2551809,346.6461 L89.3601809,346.6461 C89.0681809,351.3111 88.5751809,355.5291 88.0181809,359.6551 C88.0181809,359.6551 85.4531809,378.8001 85.4071809,379.4571 L85.3801809,379.4571 L84.8411809,386.3321 C84.8411809,386.3321 84.2111809,401.7511 83.9561809,405.5491 C83.7001809,409.3561 83.9921809,413.4741 84.0291809,414.2311 C84.1471809,416.2671 83.6181809,418.0571 83.1431809,419.6541 C82.7961809,420.8231 82.4861809,421.8911 82.4861809,422.8591 C82.5041809,425.2321 84.2751809,429.9711 82.6141809,433.7781 C81.2721809,436.8451 76.8261809,436.3061 76.8261809,436.3061 C76.8261809,436.3061 74.4521809,436.3431 70.4531809,436.3431 C63.9801809,436.3431 64.4101809,427.7251 65.1401809,425.9541 C65.9891809,423.9271 66.5091809,422.4751 66.8291809,421.3891 C67.6691809,418.5411 67.0941809,418.1481 67.0751809,416.3951 C67.0481809,414.3961 68.4451809,409.7761 68.4451809,409.7761 C68.4451809,409.7761 68.6461809,409.2561 68.7461809,401.6241 C68.8191809,395.6801 66.3181809,378.1701 66.3181809,378.1701 C65.3951809,371.9161 63.9711809,365.8911 62.9121809,358.5231 L62.7481809,357.9481 C62.3651809,354.5701 62.2551809,350.2701 62.2551809,346.6461"
        />

        <path
          aria-label="legRightUpper.back"
          className={getAreaClassName("legRightUpper.back")}
          d="M142.010081,272.2226 L108.888081,272.2226 C108.185081,266.8546 107.190081,261.6416 106.524081,255.3236 C105.812081,248.6686 103.986081,239.9776 103.219081,232.2626 C103.091081,230.9576 102.954081,227.0316 102.954081,227.0316 C102.945081,226.7856 103.237081,226.6576 103.420081,226.8306 C107.528081,230.6836 112.321081,231.7786 116.794081,231.5506 C123.614081,231.2126 129.685081,227.8896 131.466081,226.8216 C131.758081,226.6486 131.840081,226.2646 131.648081,225.9906 C131.475081,225.7266 131.128081,225.6436 130.863081,225.8086 C127.996081,227.5246 112.860081,235.7596 103.064081,224.8046 C102.918081,224.6306 102.835081,224.4116 102.835081,224.1836 L102.826081,205.9336 C102.826081,205.4226 103.237081,205.0206 103.739081,205.0206 L140.988081,205.0206 C141.490081,205.0206 141.891081,205.4316 141.900081,205.9336 C141.900081,206.4636 141.910081,206.7006 141.955081,206.9926 C142.375081,209.8866 142.768081,215.7846 143.115081,217.6466 C143.462081,219.4996 144.055081,231.3226 144.055081,231.3226 C144.311081,236.4806 142.695081,265.3756 142.174081,269.1736 C142.028081,269.9946 142.028081,271.0266 142.010081,272.2226"
        />
        <path
          aria-label="legRightMiddle.back"
          className={getAreaClassName("legRightMiddle.back")}
          d="M139.015781,344.7836 L111.818781,344.7836 C111.718781,341.7256 111.727781,338.4846 111.855781,334.9606 C111.526781,326.7896 110.951781,318.7186 110.951781,318.7186 C110.951781,318.7186 109.874781,312.4746 109.773781,310.3476 C109.664781,308.2206 109.609781,303.7926 109.581781,301.4096 C109.545781,299.0176 110.138781,288.6736 110.111781,286.3826 C109.947781,283.7436 109.554781,278.6776 109.554781,278.6776 C109.445781,277.1066 109.299781,275.5826 109.125781,274.0846 L141.964781,274.0846 C141.909781,275.0896 141.818781,276.1756 141.617781,277.3256 C141.617781,277.3256 137.244781,298.4786 136.988781,304.0386 C136.915781,306.0926 136.815781,317.7696 137.271781,321.7596 C137.728781,325.7396 138.860781,338.1286 138.960781,340.1636 C138.997781,340.8666 139.024781,342.5746 139.015781,344.7836"
        />
        <path
          aria-label="legRightLower.back"
          className={getAreaClassName("legRightLower.back")}
          d="M146.930881,431.4405 C147.076881,434.3435 143.990881,435.4485 138.485881,435.7225 C136.112881,435.8405 134.012881,435.1655 132.369881,435.6035 C131.538881,435.9775 130.588881,436.2975 129.447881,436.5255 C125.083881,437.3925 118.044881,437.1555 116.657881,434.6995 C114.931881,431.6595 116.492881,428.7925 116.693881,426.9035 C117.077881,423.2785 116.419881,422.2475 116.237881,420.7495 C116.173881,420.2475 116.164881,419.6905 116.264881,418.9605 C116.346881,418.3575 117.086881,415.5555 116.931881,412.5155 C116.894881,411.7025 116.657881,409.1095 116.428881,405.6495 C116.401881,405.3575 116.383881,405.0655 116.365881,404.7915 L116.365881,404.7455 C116.191881,402.1445 116.027881,399.1315 115.936881,396.0365 L115.963881,396.0365 C115.744881,391.2345 115.552881,386.7975 115.552881,386.7975 C115.552881,386.7975 115.306881,380.5715 115.233881,379.0095 L112.996881,359.7745 C112.503881,355.6115 112.092881,351.3475 111.891881,346.6465 L138.997881,346.6465 C138.942881,350.3795 138.768881,354.9625 138.303881,358.5055 C138.303881,358.5055 134.788881,375.7875 133.601881,383.4925 C133.601881,383.4925 131.684881,397.5795 131.510881,402.0705 C131.483881,402.9935 131.456881,403.8055 131.437881,404.5355 C131.446881,404.7365 131.446881,404.9285 131.456881,405.0925 C131.793881,411.8945 133.144881,412.1225 133.072881,415.4545 C133.053881,416.4225 132.642881,417.4995 132.925881,418.9425 C133.126881,420.3575 133.939881,419.8645 135.801881,422.4385 C136.568881,423.1235 138.257881,423.8085 139.517881,424.8765 C139.572881,424.9225 139.636881,424.9675 139.690881,425.0225 C144.547881,429.4325 146.830881,429.4595 146.930881,431.4405"
        />
      </svg>
    );
  } else if (type === "AdultFront") {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox="0 0 204 458"
        {...baseArgs}
      >
        <path
          d="M160.3 434.9c-5.6-11.2-66.9-22.4-94.8-11.2-11.3 4.5-31.6 3.7-33.5 11.4 9.5 13.7 49 26 71.8 22.3 34.2-5.7 62.1-11.3 56.5-22.5"
          className={cssClasses.ground}
        />
        <path
          aria-label="headAndNeck.front"
          className={getAreaClassName("headAndNeck.front")}
          d="M83.3721796,39.184 C83.4141796,39.171 83.4521796,39.148 83.4901796,39.125 C83.8881796,41.486 84.8301796,46.68 85.1141796,47.481 C86.3331796,50.907 86.8481796,51.796 89.0271796,54.215 C91.7471796,56.936 96.8621796,62.489 102.29618,62.489 C107.73018,62.489 112.84418,56.936 115.56518,54.215 C117.74318,51.796 118.25818,50.907 119.47718,47.481 C119.76218,46.68 120.71018,41.538 121.10118,39.125 C121.13918,39.148 121.17718,39.171 121.21918,39.184 C122.13618,39.465 123.63018,37.246 124.55818,34.226 C125.48518,31.206 125.49418,28.53 124.57818,28.248 C124.34318,28.176 124.06918,28.271 123.77818,28.499 C125.03918,18.029 122.14918,10.697 115.03718,4.977 C114.31018,4.357 113.54218,3.789 112.74018,3.269 C112.71618,3.252 112.69418,3.235 112.66918,3.218 C112.66118,3.213 112.65218,3.211 112.64518,3.206 C109.64018,1.281 106.14718,0.1 102.35818,0 L102.23318,0 C98.4441796,0.1 94.9511796,1.281 91.9471796,3.206 C91.9391796,3.211 91.9301796,3.213 91.9231796,3.218 C91.8981796,3.235 91.8761796,3.252 91.8511796,3.269 C91.0501796,3.789 90.2811796,4.357 89.5551796,4.977 C82.4421796,10.697 79.5521796,18.029 80.8141796,28.499 C80.5221796,28.271 80.2491796,28.176 80.0141796,28.248 C79.0971796,28.53 79.1061796,31.206 80.0341796,34.226 C80.9611796,37.246 82.4561796,39.465 83.3721796,39.184"
        />

        <path
          aria-label="anteriorTrunkUpper.front"
          className={getAreaClassName("anteriorTrunkUpper.front")}
          d="M136.70768,75.2106 L130.84668,73.5576 C119.79068,71.1206 115.48168,67.2036 115.44568,57.6636 C115.44568,57.3346 115.06168,57.1706 114.83368,57.3986 C111.74768,60.4296 107.27468,64.3646 102.29868,64.3646 C97.3236796,64.3646 92.8496796,60.4296 89.7646796,57.3986 C89.5266796,57.1706 89.1436796,57.3346 89.1436796,57.6636 C89.1156796,67.2036 84.7976796,71.1206 73.7506796,73.5576 L67.8896796,75.2106 C67.4976796,75.3196 67.2236796,75.6856 67.2236796,76.0956 L67.2236796,91.1326 L137.36568,91.1326 L137.36568,76.0956 C137.36568,75.6856 137.10068,75.3196 136.70768,75.2106 L136.70768,75.2106 Z"
        />
        <path
          aria-label="anteriorTrunkMiddle.front"
          className={getAreaClassName("anteriorTrunkMiddle.front")}
          d="M67.2236796,123.4686 C67.3056796,126.9016 68.5106796,147.3336 68.5926796,150.1176 C68.6566796,152.3276 68.6206796,157.7596 68.6016796,160.4976 L135.98668,160.4976 C135.96868,157.7596 135.93168,152.3276 135.99568,150.1176 C136.07768,147.3336 137.28268,126.9016 137.36568,123.4686 L137.36568,92.9946 L67.2236796,92.9946 L67.2236796,123.4686 Z"
        />
        <path
          aria-label="anteriorTrunkLower.front"
          className={getAreaClassName("anteriorTrunkLower.front")}
          d="M103.75968,175.3516 C103.56768,175.3516 103.41268,175.1876 103.41268,174.9866 C103.41268,173.8906 102.90168,172.9596 102.29868,172.9596 C101.69668,172.9596 101.18568,173.8906 101.18568,174.9866 C101.18568,175.1876 101.02968,175.3516 100.83868,175.3516 C100.63768,175.3516 100.48268,175.1876 100.48268,174.9866 C100.48268,173.4526 101.27668,172.2386 102.29868,172.2386 C103.31268,172.2386 104.10668,173.4526 104.10668,174.9866 C104.10668,175.1876 103.95168,175.3516 103.75968,175.3516 L103.75968,175.3516 Z M141.55568,191.3826 C140.76168,184.7006 138.10468,174.0096 136.72668,166.1496 L136.22368,163.5746 C136.15968,163.1456 136.08668,162.7436 136.03268,162.3606 L68.5566796,162.3606 C68.5016796,162.7436 68.4376796,163.1456 68.3646796,163.5746 L67.8986796,165.6836 C66.5206796,173.5346 63.8546796,184.7006 63.0606796,191.3826 C62.8596796,192.8896 63.0696796,200.7586 62.9594945,205.7346 C62.9506796,206.1816 63.2706796,206.5656 63.7176796,206.6476 L81.7306796,210.0616 C82.1136796,210.1346 82.4876796,209.9616 82.6796796,209.6326 C86.6876796,202.9046 94.0276796,198.3946 102.42668,198.3946 C110.85368,198.3946 118.21168,202.9316 122.21068,209.6966 C122.41168,210.0346 122.78568,210.2076 123.16868,210.1346 L140.91668,206.5466 C141.35468,206.4646 141.65568,206.0726 141.646876,205.6336 C141.54668,200.7136 141.75668,192.8896 141.55568,191.3826 L141.55568,191.3826 Z"
        />

        <path
          aria-label="armRightUpper.front"
          className={getAreaClassName("armRightUpper.front")}
          d="M65.3610796,77.0273 L65.3610796,108.0953 C65.3610796,108.3503 65.3060796,108.5973 65.2060796,108.8253 C64.3200796,110.8333 59.8190796,121.0313 58.1030796,125.3223 C57.9940796,125.5873 57.8570796,125.9153 57.6920796,126.3083 L37.7350796,120.7943 C38.5480796,118.2373 39.3510796,115.6723 40.1820796,113.0433 L40.3100796,113.0703 C42.7290796,105.4383 45.3950796,97.2303 49.0560796,87.1613 C52.3610796,77.7213 57.4000796,77.9763 61.3990796,76.8723 C61.9830796,76.7173 63.2700796,76.3793 64.2200796,76.1413 C64.8040796,75.9953 65.3610796,76.4243 65.3610796,77.0273"
        />
        <path
          aria-label="armRightMiddle.front"
          className={getAreaClassName("armRightMiddle.front")}
          d="M56.9528796,128.0427 C53.6938796,135.5467 45.6778796,153.3407 43.3498796,160.0597 C43.3498796,160.0597 42.9938796,161.3287 42.4278796,163.3097 L25.1918796,158.5537 C27.9848796,151.5507 31.5088796,139.6007 34.2748796,131.4387 C35.2888796,128.4257 36.2378796,125.4957 37.1688796,122.5737 L56.9528796,128.0427 Z"
        />
        <path
          aria-label="armRightLower.front"
          className={getAreaClassName("armRightLower.front")}
          d="M24.5249796,160.2971 L41.9069796,165.0991 C40.3009796,170.6591 37.7169796,179.3961 36.1649796,183.6961 C33.4809796,191.1451 28.1769796,204.0911 26.1769796,210.3361 C26.1679796,210.3541 26.1679796,210.3811 26.1589796,210.3991 C26.0859796,210.6281 25.8579796,210.7561 25.6209796,210.7011 L15.5959796,208.2361 C15.3589796,208.1811 15.2129796,207.9531 15.2589796,207.7061 C15.2859796,207.5881 15.3039796,207.4691 15.3319796,207.3411 C16.2169796,202.8221 16.8109796,197.8281 17.0749796,194.8611 C17.4589796,190.5981 19.1479796,178.8021 20.1789796,174.1011 C20.9279796,170.6861 23.6389796,162.8351 24.5249796,160.2971"
        />

        <path
          aria-label="armLeftUpper.front"
          className={getAreaClassName("armLeftUpper.front")}
          d="M166.95378,120.8031 L146.99678,126.3171 C146.82378,125.9251 146.68678,125.5871 146.57678,125.3221 C144.86078,121.0311 140.35978,110.8341 139.47378,108.8251 C139.37378,108.5971 139.31878,108.3501 139.31878,108.0951 L139.31878,77.0271 C139.31878,76.4251 139.88478,75.9961 140.45978,76.1421 C141.40978,76.3791 142.69678,76.7171 143.28078,76.8721 C147.27978,77.9771 152.31878,77.7211 155.63278,87.1611 C159.28478,97.2311 161.95078,105.4381 164.36978,113.0701 L164.49778,113.0431 C165.32878,115.6721 166.14078,118.2381 166.95378,120.8031"
        />
        <path
          aria-label="armLeftMiddle.front"
          className={getAreaClassName("armLeftMiddle.front")}
          d="M179.48858,158.5626 L162.26158,163.3186 C161.68658,161.3286 161.32958,160.0596 161.32958,160.0596 C159.00158,153.3496 150.99558,135.5656 147.73658,128.0516 L167.51958,122.5836 C168.45158,125.4956 169.39158,128.4266 170.40458,131.4386 C173.17058,139.6006 176.69458,151.5606 179.48858,158.5626"
        />
        <path
          aria-label="armLeftLower.front"
          className={getAreaClassName("armLeftLower.front")}
          d="M189.42138,207.6973 C189.46738,207.9343 189.32138,208.1723 189.08338,208.2273 L179.05938,210.6913 C178.82238,210.7463 178.59338,210.6183 178.52038,210.3903 C176.53938,204.1553 171.20838,191.1733 168.51538,183.6963 C166.96338,179.3963 164.38838,170.6683 162.78138,165.1083 L180.15538,160.3063 C181.04938,162.8533 183.75238,170.6863 184.50038,174.1013 C185.53238,178.8023 187.22138,190.5983 187.60438,194.8613 C187.87838,197.9103 188.49038,203.0773 189.42138,207.6973"
        />

        <path
          aria-label="handRight.front"
          className={getAreaClassName("handRight.front")}
          d="M25.2611796,213.6996 C25.3701796,213.2876 25.4601796,212.9106 25.5391796,212.5866 C25.5991796,212.3416 25.4481796,212.0996 25.2031796,212.0396 L15.3211796,209.6136 C15.0741796,209.5526 14.8271796,209.7086 14.7691796,209.9556 C14.6711796,210.3726 14.5701796,210.7816 14.4661796,211.1766 L14.2711796,211.6816 C13.0021796,213.1186 9.45517964,214.6676 7.84617964,216.1726 C5.82717964,218.0596 1.42117964,224.5856 0.213179639,225.7366 C-1.00582036,226.8996 3.32117964,227.9526 5.13617964,226.4946 C6.22417964,225.6206 8.53717964,223.2376 8.95317964,223.3286 C12.8871796,224.1946 18.5081796,222.2056 19.2371796,218.8926 L19.2371796,218.8936 C19.2791796,218.7066 19.4641796,218.5886 19.6521796,218.6296 C19.8391796,218.6706 19.9581796,218.8556 19.9181796,219.0426 L19.9181796,219.0436 C19.9181796,219.0436 19.9181796,219.0436 19.9181796,219.0446 C19.9171796,219.0446 19.9181796,219.0446 19.9171796,219.0446 C19.9171796,219.0456 19.9171796,219.0456 19.9171796,219.0456 C19.1201796,222.6606 13.2721796,224.8636 9.05317964,224.0536 C8.48317964,225.7016 8.02217964,229.1376 7.79817964,231.0506 C7.24617964,235.7866 6.80117964,240.7516 7.28817964,243.0236 C7.55017964,244.2456 9.38117964,244.8666 10.3041796,244.0596 C10.3441796,244.6346 11.0721796,246.1456 12.0221796,246.3826 C13.0011796,246.6276 14.7011796,245.9136 15.0061796,245.4296 C16.1061796,246.2366 17.9611796,245.9526 19.4821796,242.4226 C19.9701796,243.1306 21.3041796,242.7246 21.6781796,242.4776 C23.4671796,241.2966 25.8871796,233.5536 26.5771796,230.9256 C27.2471796,228.3776 28.0571796,223.8216 28.0571796,220.5836 C28.0571796,217.0046 25.3691796,215.1466 25.2611796,213.6996"
        />
        <path
          aria-label="handLeft.front"
          className={getAreaClassName("handLeft.front")}
          d="M179.40098,213.6996 C179.29198,213.2876 179.20198,212.9106 179.12298,212.5866 C179.06298,212.3416 179.21398,212.0996 179.45898,212.0396 L189.34098,209.6136 C189.58798,209.5526 189.83498,209.7086 189.89298,209.9556 C189.99098,210.3726 190.09198,210.7816 190.19598,211.1766 L190.39098,211.6816 C191.65998,213.1186 195.20698,214.6676 196.81598,216.1726 C198.83498,218.0596 203.24098,224.5856 204.44898,225.7366 C205.66798,226.8996 201.34098,227.9526 199.52598,226.4946 C198.43798,225.6206 196.12498,223.2376 195.70898,223.3286 C191.77498,224.1946 186.15398,222.2056 185.42498,218.8926 L185.42498,218.8936 C185.38298,218.7066 185.19798,218.5886 185.00998,218.6296 C184.82298,218.6706 184.70398,218.8556 184.74398,219.0426 L184.74398,219.0436 C184.74398,219.0436 184.74398,219.0436 184.74498,219.0446 C184.74498,219.0446 184.74498,219.0446 184.74498,219.0446 C184.74498,219.0456 184.74498,219.0456 184.74498,219.0456 C185.54198,222.6606 191.38998,224.8636 195.60898,224.0536 C196.17898,225.7016 196.63998,229.1376 196.86398,231.0506 C197.41598,235.7866 197.86098,240.7516 197.37398,243.0236 C197.11198,244.2456 195.28098,244.8666 194.35798,244.0596 C194.31798,244.6346 193.59098,246.1456 192.63998,246.3826 C191.66098,246.6276 189.96098,245.9136 189.65598,245.4296 C188.55598,246.2366 186.70098,245.9526 185.17998,242.4226 C184.69198,243.1306 183.35798,242.7246 182.98398,242.4776 C181.19498,241.2966 178.77498,233.5536 178.08498,230.9256 C177.41498,228.3776 176.60498,223.8216 176.60498,220.5836 C176.60498,217.0046 179.29298,215.1466 179.40098,213.6996"
        />

        <path
          aria-label="genitals"
          className={getAreaClassName("genitals")}
          d="M123.56908,221.3676 C123.56908,233.0446 114.10308,242.5116 102.42508,242.5116 C90.7480796,242.5116 81.2820796,233.0446 81.2820796,221.3676 C81.2820796,209.6906 90.7480796,200.2236 102.42508,200.2236 C114.10308,200.2236 123.56908,209.6906 123.56908,221.3676"
        />

        <path
          aria-label="legLeftUpper.front"
          className={getAreaClassName("legLeftUpper.front")}
          d="M144.30358,274.1872 L111.15458,274.1872 C109.76658,261.8172 108.07758,247.9222 107.73058,244.4982 C107.68558,244.0512 107.97758,243.6582 108.40658,243.5492 C118.19358,240.9102 125.39658,231.9822 125.39658,221.3642 C125.39658,218.3972 124.82158,215.5582 123.79858,212.9472 C123.58858,212.4262 123.91758,211.8422 124.47458,211.7322 L140.98058,208.4462 C141.42758,208.3452 141.93858,208.5552 142.08558,209.1122 C142.81558,211.9422 143.87458,217.0552 144.27658,218.8992 C144.67758,220.7522 145.61858,229.1152 145.59058,232.5482 C145.54558,237.4142 145.05258,266.2172 144.75058,270.4352 C144.67758,271.4122 144.52258,272.6902 144.30358,274.1872"
        />
        <path
          aria-label="legLeftMiddle.front"
          className={getAreaClassName("legLeftMiddle.front")}
          d="M143.20808,346.7482 L115.99308,346.7482 C115.82008,343.7262 115.72808,340.5222 115.76508,337.0252 C115.21708,328.8732 114.42308,320.8202 114.42308,320.8202 C114.42308,320.8202 113.17208,314.6032 113.00808,312.4762 C112.84308,310.3582 112.67008,305.9302 112.57008,303.5472 C112.49608,301.8132 112.80708,290.3192 112.79808,289.8992 C112.48708,286.3842 111.95808,281.4272 111.36408,276.0492 L144.02108,276.0492 C143.42708,279.9022 142.59608,284.7592 141.93008,289.5982 C141.93008,289.5982 140.11308,301.9132 140.04008,305.4282 C140.03108,307.4822 140.25008,319.1592 140.81608,323.1302 C141.37308,327.0922 142.85208,339.4442 143.00708,341.4802 C143.06208,342.2472 143.15308,344.2282 143.20808,346.7482"
        />
        <path
          aria-label="legLeftLower.front"
          className={getAreaClassName("legLeftLower.front")}
          d="M149.47088,438.0519 C149.29788,438.7549 147.06988,439.0649 146.27588,436.9199 C146.27588,437.8599 148.07388,440.2519 146.54988,441.3109 C145.29888,442.1879 143.78288,439.9689 143.53688,439.6039 C143.71888,440.0699 144.92488,443.2469 142.99788,443.6569 C141.32788,444.0229 139.82988,441.2929 139.69288,441.0369 C139.77588,441.2659 140.84388,444.2329 139.43788,444.9079 C136.83588,446.1409 135.11888,441.8499 135.11888,441.8499 C135.11888,441.8499 135.46588,447.0809 133.26588,447.2449 C131.05688,447.4189 129.84288,445.8579 128.71088,441.1099 C128.38188,439.7409 127.58788,436.8739 127.31388,435.5319 C127.03988,434.1899 124.98588,429.2149 124.20088,427.9089 C123.41588,426.6039 122.76688,425.3989 122.42988,424.4029 C122.10088,423.4079 121.85388,423.4629 121.67188,421.8469 C121.63488,421.5639 121.61688,421.2899 121.61688,421.0259 C121.45288,417.2639 123.23288,415.6569 123.03188,414.0779 C122.47488,407.2039 120.93188,388.8809 120.36588,380.9469 C119.73588,374.7299 117.90988,363.9029 117.90988,363.9029 C117.17988,359.0459 116.50488,354.1429 116.11188,348.6109 L143.23588,348.6109 C143.27188,352.4719 143.18988,357.0919 142.77888,360.5429 C142.71488,361.0359 137.96788,390.0859 137.33788,403.7889 C137.34688,406.0989 137.80388,410.9279 137.80388,410.9279 C137.80388,410.9279 138.46988,414.0319 138.50688,415.5659 C138.54288,417.0999 138.75288,419.8199 139.16388,420.8059 C139.58388,421.8019 141.77488,424.7049 141.77488,424.7049 C141.77488,424.7049 141.89388,424.8689 142.09388,425.1429 L142.09388,425.1519 C142.80588,426.1469 144.48588,428.5209 144.96088,429.4159 C145.56388,430.5569 146.87788,432.1729 147.98288,434.0169 C148.62188,435.0849 149.88188,436.4269 149.47088,438.0519"
        />

        <path
          aria-label="legRightUpper.front"
          className={getAreaClassName("legRightUpper.front")}
          d="M96.8120796,244.4525 C96.5380796,247.3375 94.8130796,261.5335 93.3980796,274.1875 L60.2390796,274.1875 C60.0200796,272.6895 59.8650796,271.4115 59.8010796,270.4345 C59.5000796,266.2175 59.0070796,237.4135 58.9610796,232.5475 C58.9250796,229.1155 59.8740796,220.7525 60.2760796,218.8995 C60.6780796,217.0545 61.7270796,211.9425 62.4670796,209.1125 C62.6040796,208.5555 63.1240796,208.3455 63.5720796,208.4455 L80.3520796,211.7875 C80.8990796,211.8965 81.2370796,212.4815 81.0270796,213.0105 C80.0140796,215.5945 79.4570796,218.4155 79.4570796,221.3645 C79.4570796,231.8175 86.4410796,240.6365 95.9900796,243.4205 C96.0540796,243.4395 96.1180796,243.4575 96.1820796,243.4755 C96.5930796,243.6035 96.8670796,244.0145 96.8120796,244.4525"
        />
        <path
          aria-label="legRightMiddle.front"
          className={getAreaClassName("legRightMiddle.front")}
          d="M91.9734796,303.5476 C91.8824796,305.9306 91.7084796,310.3586 91.5444796,312.4766 C91.3804796,314.6036 90.1294796,320.8206 90.1294796,320.8206 C90.1294796,320.8206 89.3354796,328.8726 88.7874796,337.0256 C88.8234796,340.5216 88.7324796,343.7266 88.5594796,346.7486 L61.3444796,346.7486 C61.3984796,344.2286 61.4904796,342.2476 61.5444796,341.4806 C61.7004796,339.4446 63.1704796,327.0926 63.7364796,323.1306 C64.3024796,319.1586 64.5214796,307.4826 64.5124796,305.4286 C64.4394796,301.9136 62.6134796,289.5976 62.6134796,289.5976 C61.9554796,284.7596 61.1154796,279.9026 60.5224796,276.0496 L93.1874796,276.0496 C92.5944796,281.4086 92.0644796,286.3656 91.7544796,289.8986 C91.7364796,290.3186 92.0464796,301.8126 91.9734796,303.5476"
        />
        <path
          aria-label="legRightLower.front"
          className={getAreaClassName("legRightLower.front")}
          d="M61.3167796,348.6106 L88.4407796,348.6106 C88.0477796,354.1426 87.3727796,359.0456 86.6417796,363.9026 C86.6417796,363.9026 84.8157796,374.7296 84.1857796,380.9476 C83.6197796,388.8806 82.0677796,407.2036 81.5207796,414.0776 C81.3287796,415.5206 82.8077796,416.9906 82.9357796,420.1126 C82.9627796,420.6416 82.9537796,421.2176 82.8807796,421.8476 C82.6977796,423.4626 82.4517796,423.4086 82.1137796,424.4036 C81.7847796,425.3986 81.1367796,426.6036 80.3517796,427.9096 C79.5667796,429.2146 77.5127796,434.1906 77.2297796,435.5326 C76.9557796,436.8746 76.1617796,439.7406 75.8417796,441.1106 C74.7097796,445.8576 73.4957796,447.4186 71.2857796,447.2456 C69.0767796,447.0806 69.4237796,441.8496 69.4237796,441.8496 C69.4237796,441.8496 67.7077796,446.1406 65.1147796,444.9086 C63.7087796,444.2326 64.7677796,441.2656 64.8497796,441.0376 C64.7127796,441.2926 63.2247796,444.0226 61.5447796,443.6576 C59.6277796,443.2466 60.8327796,440.0696 61.0157796,439.6036 C60.7687796,439.9696 59.2537796,442.1876 58.0027796,441.3116 C56.4777796,440.2526 58.2767796,437.8606 58.2767796,436.9196 C57.4737796,439.0656 55.2547796,438.7546 55.0717796,438.0516 C54.6707796,436.4266 55.9217796,435.0846 56.5607796,434.0166 C57.6737796,432.1726 58.9887796,430.5566 59.5917796,429.4156 C60.0387796,428.5666 61.5637796,426.3936 62.3297796,425.3256 C62.6037796,424.9416 62.7777796,424.7046 62.7777796,424.7046 C62.7777796,424.7046 64.9597796,421.8016 65.3797796,420.8066 C65.7997796,419.8206 66.0097796,417.0996 66.0457796,415.5656 C66.0827796,414.0326 66.7487796,410.9286 66.7487796,410.9286 C66.7487796,410.9286 67.2057796,406.0986 67.2147796,403.7886 C66.5847796,390.0856 61.8367796,361.0356 61.7727796,360.5426 C61.3627796,357.0916 61.2807796,352.4726 61.3167796,348.6106"
        />
      </svg>
    );
  } else if (type === "ChildBack") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 213 432"
        {...baseArgs}
      >
        <path
          d="M21.59886 395.896091c-7.1-20.4 87.8-24.9 123-13.4 18.6 1.6 39.3 5.7 39.3 13.4 0 4.2 6.6 13.4 0 16.2-5.8 8-1.9 7.2-23.1 10.9-11.8 6.9-19.3 8.1-40.6 8.1-47-.1-89.2-8-98.6-35.2"
          className={cssClasses.ground}
        />
        <path
          aria-label="headAndNeck.back"
          className={getAreaClassName("headAndNeck.back")}
          d="M79.6566919,53.1176875 C80.7046919,56.5616875 84.4856919,62.9706875 87.5836919,66.2376875 C92.2756919,71.1836875 101.338692,72.1246875 106.226692,72.1246875 C111.114692,72.1246875 120.207692,71.1836875 124.899692,66.2376875 C127.997692,62.9706875 131.748692,56.5616875 132.796692,53.1176875 C134.066692,53.4246875 136.745692,50.7556875 138.013692,46.6266875 C139.296692,42.4506875 138.662692,38.3786875 137.395692,37.9896875 C137.086692,37.8946875 136.730692,38.0076875 136.350692,38.2896875 C136.336692,38.2916875 136.323692,38.2906875 136.309692,38.2926875 C136.538692,35.4896875 136.597692,30.7996875 136.597692,30.1656875 C136.597692,22.5106875 133.925692,15.5736875 129.232692,10.2496875 C129.192692,10.2046875 129.154692,10.1586875 129.114692,10.1126875 C128.671692,9.6166875 128.213692,9.1356875 127.739692,8.6696875 C127.664692,8.5966875 127.587692,8.5246875 127.511692,8.4526875 C122.079692,3.2236875 114.701692,0.0016875 106.566692,0.0016875 C106.433692,0.0016875 106.303692,-0.0013125 106.171692,0 C106.038692,-0.0013125 105.908692,0.0016875 105.775692,0.0016875 C97.6406919,0.0016875 90.2626919,3.2236875 84.8306919,8.4526875 C84.7546919,8.5246875 84.6776919,8.5966875 84.6026919,8.6696875 C84.1286919,9.1356875 83.6706919,9.6166875 83.2286919,10.1126875 C83.1886919,10.1586875 83.1496919,10.2046875 83.1096919,10.2496875 C78.4166919,15.5736875 75.7446919,22.5106875 75.7446919,30.1656875 C75.7446919,30.7956875 75.8036919,35.4346875 76.0296919,38.2426875 C75.6766919,38.0006875 75.3456919,37.9006875 75.0576919,37.9896875 C73.7906919,38.3786875 72.8536919,42.3696875 74.1356919,46.5456875 C75.4036919,50.6736875 78.3866919,53.4246875 79.6566919,53.1176875"
        />

        <path
          aria-label="anteriorTrunkUpper.back"
          className={getAreaClassName("anteriorTrunkUpper.back")}
          d="M146.364992,86.2186875 L146.364992,101.438688 L65.8749919,101.438688 L65.8749919,86.2186875 C65.8749919,85.8486875 66.1149919,85.5986875 66.4549919,85.5586875 C67.1549919,85.4586875 68.9149919,85.1486875 70.0849919,84.9086875 L70.1549919,84.8886875 C84.2749919,82.0586875 89.0849919,76.9186875 89.2749919,70.3486875 C89.2849919,70.1486875 89.4749919,70.0286875 89.6449919,70.1286875 C93.9949919,72.4486875 99.0849919,73.7386875 105.584992,73.7986875 C105.764992,73.7886875 105.934992,73.7786875 106.114992,73.7786875 L106.124992,73.7786875 C106.304992,73.7786875 106.474992,73.7886875 106.654992,73.7986875 C113.144992,73.7386875 118.254992,72.4486875 122.584992,70.1286875 C122.764992,70.0386875 122.954992,70.1486875 122.964992,70.3486875 C123.154992,76.9186875 127.964992,82.0586875 142.084992,84.8886875 L142.154992,84.9086875 C143.324992,85.1486875 145.054992,85.4686875 145.754992,85.5486875 C146.094992,85.5786875 146.364992,85.8686875 146.364992,86.2186875"
        />
        <path
          aria-label="anteriorTrunkMiddle.back"
          className={getAreaClassName("anteriorTrunkMiddle.back")}
          d="M65.8749919,103.488788 L146.364992,103.488788 L146.364992,118.428787 C146.194992,143.108788 143.444992,152.338788 143.364992,154.418788 C143.354992,154.618787 143.354992,155.678787 143.364992,157.118787 L68.8649919,157.118787 C68.8849919,155.678787 68.8849919,154.618787 68.8749919,154.418788 C68.7949919,152.338788 66.0449919,143.108788 65.8749919,118.428787 L65.8749919,103.488788 Z"
        />
        <path
          aria-label="anteriorTrunkLower.back"
          className={getAreaClassName("anteriorTrunkLower.back")}
          d="M147.234992,202.778688 C147.274992,203.538688 146.674992,204.168688 145.904992,204.168688 L66.3349919,204.168688 C65.5649919,204.168688 64.9649919,203.538688 65.0049919,202.778688 C65.2549919,198.288688 65.2649919,192.398688 65.6849919,188.308688 C66.3149919,182.218688 67.6249919,171.248688 68.4149919,166.828688 C68.6549919,165.488687 68.7849919,162.078688 68.8449919,159.168688 L143.394992,159.168688 C143.454992,162.078688 143.584992,165.488687 143.824992,166.828688 C144.614992,171.248688 145.924992,182.218688 146.554992,188.308688 C146.974992,192.398688 146.984992,198.288688 147.234992,202.778688"
        />

        <path
          aria-label="armLeftUpper.back"
          className={getAreaClassName("armLeftUpper.back")}
          d="M63.8349919,86.4686875 L63.8349919,119.898687 C63.8349919,120.308687 63.7549919,120.718687 63.5849919,121.098687 C62.4049919,123.778687 60.9149919,127.118687 59.5949919,130.108687 L39.9449919,124.668687 C41.7149919,120.338687 43.6749919,115.258688 45.2349919,111.318688 C47.4649919,105.698688 49.9549919,99.6486875 53.3749919,92.3186875 C55.6849919,87.1986875 59.6549919,86.1586875 63.0949919,85.8086875 C63.4949919,85.7686875 63.8349919,86.0686875 63.8349919,86.4686875"
        />
        <path
          aria-label="armLeftMiddle.back"
          className={getAreaClassName("armLeftMiddle.back")}
          d="M58.7549919,131.988788 C57.5049919,134.788788 56.5049919,137.018788 56.1849919,137.708788 C54.1549919,142.018788 51.7749919,147.368788 49.2549919,153.048788 C48.2149919,155.368788 46.9949919,158.138788 45.7449919,160.928788 L26.8149919,155.698788 C27.8749919,152.838788 29.1249919,149.808788 29.8849919,148.248788 C32.7149919,143.118788 36.1549919,133.568788 38.9049919,127.178788 C38.9849919,126.978788 39.0749919,126.778788 39.1549919,126.578788 L58.7549919,131.988788 Z"
        />
        <path
          aria-label="armLeftLower.back"
          className={getAreaClassName("armLeftLower.back")}
          d="M44.9049919,162.808787 C43.7149919,165.488788 42.5149919,168.128787 41.4849919,170.318787 C38.7549919,176.138788 33.5949919,186.398787 32.0749919,190.648787 C32.0749919,190.648787 31.4349919,192.688787 30.7849919,194.778787 L18.4749919,191.378787 C19.6849919,186.828788 21.1049919,181.068787 21.5449919,178.868787 C22.1849919,175.658787 24.1649919,164.578787 25.0449919,161.038788 C25.2649919,160.158788 25.6549919,158.948787 26.1149919,157.618787 L44.9049919,162.808787 Z"
        />

        <path
          aria-label="handLeft.back"
          className={getAreaClassName("handLeft.back")}
          d="M29.5349919,198.698687 C29.7249919,200.238687 32.6949919,202.968687 32.2749919,206.158687 C31.8449919,209.518687 30.5849919,214.288687 29.1249919,216.838687 C27.6949919,219.328687 25.4949919,228.588687 22.8549919,228.718687 C22.2749919,228.738687 21.9449919,228.648687 21.6649919,228.408687 C19.7649919,231.988687 18.4649919,232.208687 16.8049919,231.518687 C16.4149919,231.998687 15.1349919,232.768687 14.0049919,232.448687 C12.7449919,232.098687 12.0549919,230.908687 11.9849919,230.288687 C10.4949919,230.278687 9.51499187,229.428687 9.12499187,228.108687 C8.39499187,225.658687 8.81499187,220.448687 9.37499187,215.498687 C9.60499187,213.498687 10.0549919,210.188687 10.6349919,207.448687 C10.7549919,206.888687 7.09499187,209.608687 5.67499187,210.418687 C3.31499187,211.788687 -1.25500813,210.328687 0.324991875,209.228687 C1.88499187,208.128687 7.50499187,201.658687 10.1249919,199.868687 C12.2049919,198.438687 15.6549919,197.058687 17.2849919,195.658687 C17.4749919,195.008687 17.6949919,194.228687 17.9349919,193.348687 L30.1649919,196.728687 C29.9049919,197.548687 29.6849919,198.258687 29.5349919,198.698687"
        />

        <path
          aria-label="armRightUpper.back"
          className={getAreaClassName("armRightUpper.back")}
          d="M172.334992,124.768687 L152.694992,130.198688 C151.344992,127.188688 149.854992,123.808688 148.654992,121.098687 C148.484992,120.718687 148.404992,120.308688 148.404992,119.898687 L148.404992,86.4686875 C148.404992,86.0686875 148.744992,85.7686875 149.144992,85.8086875 C152.584992,86.1586875 156.554992,87.1986875 158.854992,92.3186875 C162.284992,99.6486875 164.774992,105.698688 167.004992,111.318688 C168.574992,115.288687 170.554992,120.418687 172.334992,124.768687"
        />
        <path
          aria-label="armRightMiddle.back"
          className={getAreaClassName("armRightMiddle.back")}
          d="M185.464992,155.788688 L166.524992,161.028688 C165.274992,158.208688 164.024992,155.398688 162.984992,153.048687 C160.464992,147.368687 158.084992,142.018688 156.054992,137.708688 C155.734992,137.028688 154.754992,134.828688 153.524992,132.078688 L173.124992,126.668688 C173.194992,126.838688 173.264992,127.008688 173.334992,127.178687 C176.084992,133.568687 179.524992,143.118687 182.354992,148.248687 C183.114992,149.828688 184.384992,152.898688 185.464992,155.788688"
        />
        <path
          aria-label="armRightLower.back"
          className={getAreaClassName("armRightLower.back")}
          d="M193.774992,191.368787 L181.454992,194.768787 C180.804992,192.678787 180.164992,190.648787 180.164992,190.648787 C178.644992,186.398787 173.484992,176.138787 170.754992,170.318787 C169.724992,168.148787 168.554992,165.558787 167.364992,162.908787 L186.154992,157.718787 C186.614992,159.008787 186.984992,160.178787 187.194992,161.038787 C188.064992,164.578787 190.054992,175.658787 190.694992,178.868787 C191.134992,181.068787 192.554992,186.818787 193.774992,191.368787"
        />

        <path
          aria-label="handRight.back"
          className={getAreaClassName("handRight.back")}
          d="M206.564992,210.418788 C205.144992,209.608788 201.484992,206.888788 201.604992,207.448788 C202.184992,210.188788 202.634992,213.498788 202.864992,215.498788 C203.424992,220.448788 203.844992,225.658788 203.114992,228.108788 C202.724992,229.428788 201.744992,230.278788 200.254992,230.288788 C200.184992,230.908788 199.494992,232.098788 198.234992,232.448788 C197.104992,232.768788 195.824992,231.998788 195.434992,231.518788 C193.774992,232.208788 192.474992,231.988788 190.574992,228.408788 C190.294992,228.648788 189.964992,228.738788 189.384992,228.718788 C186.734992,228.588788 184.544992,219.328788 183.114992,216.838788 C181.654992,214.288788 180.394992,209.518788 179.964992,206.158788 C179.544992,202.968788 182.514992,200.238788 182.704992,198.698788 C182.564992,198.248788 182.334992,197.538788 182.074992,196.718788 L194.294992,193.338788 C194.544992,194.218788 194.764992,195.008788 194.954992,195.658788 C196.584992,197.058788 200.034992,198.438788 202.114992,199.868788 C204.734992,201.658788 210.354992,208.128788 211.914992,209.228788 C213.494992,210.328788 208.914992,211.788788 206.564992,210.418788"
        />

        <path
          aria-label="legRightUpper.back"
          className={getAreaClassName("legRightUpper.back")}
          d="M151.544992,235.518687 C151.544992,243.788688 149.924992,251.868687 149.614992,255.778687 C149.554992,256.558688 149.474992,257.758688 149.344992,259.358687 C149.214992,260.958687 149.034992,262.948688 148.804992,265.308688 C148.424992,269.178688 147.874992,274.038688 147.084992,279.768687 L112.014992,279.768687 C111.414992,274.058688 110.854992,268.738687 110.464992,265.688688 C110.404992,265.238687 110.324992,264.778687 110.254992,264.318688 C110.154992,263.598687 107.134992,246.518687 107.134992,242.458687 L107.134992,240.488687 C107.134992,240.358688 107.284992,240.338687 107.354992,240.398687 C111.444992,244.088687 116.394992,245.328688 121.084992,245.328688 C128.224992,245.328688 134.754992,242.568688 136.674992,241.678687 C136.984992,241.538688 137.094992,241.188687 136.914992,240.928687 C136.784992,240.738687 136.554992,240.648687 136.324992,240.668688 C136.244992,240.668688 136.174992,240.688687 136.104992,240.728688 C134.344992,241.538688 128.324992,244.098688 121.674992,244.238687 C116.964992,244.338687 111.954992,243.208687 107.914992,239.398687 C107.404992,238.918688 107.134992,238.228688 107.134992,237.528687 L107.134992,207.538688 C107.134992,206.808687 107.734992,206.208687 108.464992,206.208687 L146.954992,206.208687 C147.274992,206.208687 147.554992,206.458687 147.604992,206.768687 C147.604992,206.768687 147.644992,206.998687 147.664992,207.138688 C147.884992,208.298687 148.514992,211.588687 149.064992,214.388688 C149.154992,214.868687 149.644992,217.338687 149.724992,217.698688 C151.104992,224.468687 151.544992,225.878688 151.544992,235.518687"
        />
        <path
          aria-label="legRightMiddle.back"
          className={getAreaClassName("legRightMiddle.back")}
          d="M142.774992,305.288688 C141.984992,309.258688 141.374992,315.798688 141.654992,318.568688 C142.614992,328.058688 142.894992,338.558688 142.254992,344.038688 L117.234992,344.038688 C117.334992,338.908687 118.204992,333.648687 117.884992,327.938688 C117.534992,321.478688 116.004992,320.068688 115.234992,315.328688 C114.654992,311.728688 115.294992,305.948688 114.724992,302.898688 C114.114992,299.618688 113.144992,290.498688 112.224992,281.808688 L146.804992,281.808688 C145.824992,288.588687 144.524992,296.478688 142.774992,305.288688"
        />
        <path
          aria-label="legRightLower.back"
          className={getAreaClassName("legRightLower.back")}
          d="M136.734992,370.898687 C136.004992,373.998688 134.934992,376.758688 135.894992,381.208687 C136.354992,383.328688 135.864992,385.448688 135.684992,387.018687 C135.334992,390.058688 136.304992,391.298688 137.524992,395.218687 C138.064992,396.938688 138.714992,401.798688 133.454992,402.668688 C129.314992,403.068688 122.614992,403.228687 120.874992,402.008688 C119.124992,400.788687 118.634992,398.108687 119.454992,395.788687 C121.354992,390.398687 119.054992,389.068688 119.734992,386.258688 C119.864992,385.708687 121.184992,382.138688 121.184992,379.288687 C121.184992,376.438688 120.804992,372.888688 120.514992,370.788687 C119.274992,361.878688 117.374992,349.738688 117.244992,346.648687 C117.234992,346.458687 117.234992,346.268687 117.234992,346.078688 L141.934992,346.078688 C140.934992,351.668688 138.224992,364.558688 136.734992,370.898687"
        />

        <path
          aria-label="legLeftUpper.back"
          className={getAreaClassName("legLeftUpper.back")}
          d="M75.3249919,240.928787 C75.1449919,241.188787 75.2449919,241.538788 75.5649919,241.678787 C77.4749919,242.568787 84.0049919,245.328788 91.1549919,245.328788 C95.8549919,245.328788 100.804992,244.088787 104.904992,240.378787 C104.974992,240.318787 105.104992,240.348787 105.104992,240.478788 L105.104992,242.418788 C105.104992,245.738788 101.814992,265.428787 101.774992,265.688787 C101.374992,268.738788 100.814992,274.058787 100.224992,279.768788 L65.1549919,279.768788 C64.3649919,274.038788 63.8249919,269.178787 63.4349919,265.308787 C62.9649919,260.588787 62.7449919,257.338787 62.6249919,255.778787 C62.3149919,251.868787 60.6949919,243.788788 60.6949919,235.518788 C60.6949919,225.878787 61.1349919,224.468787 62.5149919,217.698788 C62.5949919,217.338787 63.0849919,214.868787 63.1749919,214.388788 C63.7249919,211.588787 64.3949919,207.878787 64.6349919,206.728788 C64.6949919,206.428787 64.9749919,206.208787 65.2849919,206.208787 L103.774992,206.208787 C104.504992,206.208787 105.104992,206.808787 105.104992,207.538788 L105.104992,237.508788 C105.104992,238.208787 104.834992,238.898787 104.324992,239.378787 C101.234992,242.298788 97.5849919,243.658787 93.9249919,244.088787 C93.8549919,244.088787 93.7849919,244.098787 93.7149919,244.108788 C93.4049919,244.138788 93.1049919,244.168788 92.7949919,244.188787 C92.6849919,244.198788 92.5749919,244.198788 92.4649919,244.198788 C92.2049919,244.218787 91.9349919,244.228788 91.6749919,244.238788 L90.5649919,244.238788 C90.2749919,244.228788 89.9849919,244.218787 89.6949919,244.198788 L89.4649919,244.198788 C89.1149919,244.168788 88.7649919,244.148787 88.4149919,244.108788 L88.3849919,244.108788 C82.6349919,243.558787 77.6949919,241.448788 76.1349919,240.728788 C76.0649919,240.688787 75.9949919,240.668788 75.9149919,240.668788 C75.6849919,240.648787 75.4549919,240.738788 75.3249919,240.928787"
        />
        <path
          aria-label="legLeftMiddle.back"
          className={getAreaClassName("legLeftMiddle.back")}
          d="M97.5149919,302.898688 C96.9449919,305.948688 97.5749919,311.728688 96.9949919,315.328688 C96.2349919,320.068688 94.7049919,321.478688 94.3549919,327.938688 C94.0249919,333.808688 94.4549919,338.858688 94.3949919,344.038688 L71.3349919,344.038688 C70.5349919,338.938688 70.3049919,329.198688 70.5749919,318.568688 C70.6549919,315.778687 70.2549919,309.258688 69.4649919,305.288688 C67.7349919,296.588687 66.4449919,288.788687 65.4749919,282.058688 C65.4649919,281.978687 65.4549919,281.888688 65.4349919,281.808688 L100.004992,281.808688 C99.0949919,290.498688 98.1249919,299.618688 97.5149919,302.898688"
        />
        <path
          aria-label="legLeftLower.back"
          className={getAreaClassName("legLeftLower.back")}
          d="M71.6649919,346.078688 L94.3449919,346.078688 L94.3449919,346.178688 C94.1549919,350.828688 91.3249919,377.058688 91.3249919,379.908687 C91.3249919,382.758688 92.1449919,385.348687 92.2449919,385.898687 C92.7549919,388.578688 91.5149919,388.848687 92.2149919,393.348687 C92.4849919,395.098687 94.0849919,397.708687 92.6149919,400.628688 C91.4349919,402.988688 84.8649919,403.538687 80.7549919,402.928688 C79.6749919,402.768687 78.7649919,402.518687 77.9749919,402.208687 C76.4249919,401.878688 74.4849919,400.788687 72.2649919,400.788687 C67.1149919,400.788687 64.1749919,399.898687 64.1749919,397.188688 C64.1749919,395.328688 66.1549919,395.728687 70.6449919,392.668688 C71.9749919,391.758688 73.4349919,390.768687 74.1549919,390.068688 C75.7749919,387.578688 76.5549919,387.998688 76.6749919,386.658687 C76.8749919,385.308688 76.4449919,384.318688 76.3749919,383.418688 C76.1549919,380.308688 77.9849919,380.018687 76.1949919,373.658687 C75.0949919,369.718687 72.8249919,353.298687 71.6649919,346.078688"
        />
      </svg>
    );
  } else if (type === "ChildFront") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 213 436"
        {...baseArgs}
      >
        <path
          d="M186.2668 405.571262c-7.2-14.4-86-28.7-121.8-14.4-14.5 5.8-40.5 4.7-43 14.6 12.1 17.5 62.9 33.2 92.3 28.4 43.8-7.1 79.6-14.3 72.5-28.6"
          className={cssClasses.ground}
        />
        <path
          aria-label="headAndNeck.front"
          className={getAreaClassName("headAndNeck.front")}
          d="M137.39006,39.2596875 C137.06206,39.1726875 136.68106,39.2866875 136.27406,39.5616875 C136.60706,37.1736875 136.68606,30.9806875 136.68606,30.2456875 C136.68606,22.5706875 134.00106,15.6146875 129.28706,10.2766875 C129.24706,10.2316875 129.20806,10.1856875 129.16806,10.1396875 C128.72306,9.6426875 128.26306,9.1596875 127.78706,8.6926875 C127.71206,8.6196875 127.63406,8.5476875 127.55806,8.4746875 C122.10106,3.2316875 114.68806,0.0016875 106.51506,0.0016875 C106.38206,0.0016875 106.25106,-0.0013125 106.11806,0 C105.98506,-0.0013125 105.85506,0.0016875 105.72106,0.0016875 C97.5480599,0.0016875 90.1360599,3.2316875 84.6790599,8.4746875 C84.6030599,8.5476875 84.5250599,8.6196875 84.4500599,8.6926875 C83.9740599,9.1596875 83.5130599,9.6426875 83.0690599,10.1396875 C83.0290599,10.1856875 82.9900599,10.2316875 82.9500599,10.2766875 C78.2350599,15.6146875 75.5500599,22.5706875 75.5500599,30.2456875 C75.5500599,30.9806875 75.6300599,37.1736875 75.9620599,39.5616875 C75.5550599,39.2866875 75.1740599,39.1726875 74.8470599,39.2596875 C73.5690599,39.5986875 73.5810599,42.8276875 74.8740599,46.4706875 C76.1670599,50.1136875 78.2510599,52.7916875 79.5290599,52.4526875 C79.5880599,52.4366875 79.6410599,52.4086875 79.6940599,52.3806875 C80.2490599,55.2296875 84.5780599,63.5166875 87.6160599,66.4356875 C91.4090599,69.7176875 98.5400599,76.4166875 106.11706,76.4166875 C106.11706,76.4166875 106.11806,76.4166875 106.11806,76.4166875 C106.11906,76.4166875 106.11906,76.4166875 106.12006,76.4166875 C113.69606,76.4166875 120.82806,69.7176875 124.62106,66.4356875 C127.65806,63.5166875 131.98806,55.2296875 132.54206,52.3806875 C132.59606,52.4086875 132.64906,52.4366875 132.70706,52.4526875 C133.98506,52.7916875 136.06906,50.1136875 137.36206,46.4706875 C138.65506,42.8276875 138.66806,39.5986875 137.39006,39.2596875"
        />

        <path
          aria-label="anteriorTrunkUpper.front"
          className={getAreaClassName("anteriorTrunkUpper.front")}
          d="M145.75166,88.8135875 C145.05166,88.7335875 143.32166,88.4135875 142.15166,88.1735875 L142.08166,88.1535875 C129.00166,85.5335875 123.56166,81.0435875 122.68166,75.1735875 C122.54166,74.0535875 122.48166,72.8435875 122.49166,71.5335875 C122.49166,71.2835875 122.20166,71.1335875 122.01166,71.2935875 C118.24166,74.4735875 112.55166,78.5235875 106.12166,78.5735875 C99.6816599,78.5235875 93.9916599,74.4635875 90.2216599,71.2935875 C90.0316599,71.1335875 89.7416599,71.2735875 89.7416599,71.5235875 C89.7516599,72.4335875 89.7116599,73.2735875 89.6516599,74.0835875 C89.0116599,80.5235875 84.1216599,85.3535875 70.1616599,88.1535875 L70.0816599,88.1735875 C68.9116599,88.4135875 67.1516599,88.7235875 66.4616599,88.8235875 C66.1116599,88.8635875 65.8716599,89.1135875 65.8716599,89.4835875 L65.8716599,105.293588 L146.36166,105.293588 L146.36166,89.4835875 C146.36166,89.1335875 146.09166,88.8435875 145.75166,88.8135875"
        />
        <path
          aria-label="anteriorTrunkMiddle.front"
          className={getAreaClassName("anteriorTrunkMiddle.front")}
          d="M68.8616599,160.973588 L143.37166,160.973588 C143.35166,159.243588 143.35166,157.903588 143.36166,157.683588 C143.44166,155.603588 146.19166,146.373588 146.36166,121.693588 L146.36166,107.343588 L65.8716599,107.343588 L65.8716599,121.693588 C66.0516599,146.373588 68.7916599,155.603588 68.8716599,157.683588 C68.8816599,157.903588 68.8816599,159.243588 68.8616599,160.973588"
        />
        <path
          aria-label="anteriorTrunkLower.front"
          className={getAreaClassName("anteriorTrunkLower.front")}
          d="M107.72166,188.193588 C107.51166,188.193588 107.34166,188.013587 107.34166,187.793588 C107.34166,186.593588 106.78166,185.573588 106.12166,185.573588 C105.46166,185.573588 104.90166,186.593588 104.90166,187.793588 C104.90166,188.013587 104.73166,188.193588 104.52166,188.193588 C104.30166,188.193588 104.13166,188.013587 104.13166,187.793588 C104.13166,186.113588 105.01166,184.783588 106.12166,184.783588 C107.23166,184.783588 108.10166,186.113588 108.10166,187.793588 C108.10166,188.013587 107.93166,188.193588 107.72166,188.193588 M147.63166,210.273587 C147.44166,209.233587 147.31166,207.873587 147.22166,206.323587 C147.23166,206.233587 147.24166,206.143587 147.23166,206.043587 C146.98166,201.553587 146.97166,195.663587 146.55166,191.573587 C145.92166,185.483587 144.61166,174.513587 143.82166,170.093587 C143.59166,168.843587 143.47166,165.793587 143.41166,163.023587 L68.8216599,163.023587 C68.7616599,165.793587 68.6416599,168.843587 68.4116599,170.093587 C67.6216599,174.513587 66.3216599,185.483587 65.6816599,191.573587 C65.2616599,195.663587 65.2616599,201.553587 65.0016599,206.043587 C65.0016599,206.143587 65.0016599,206.233587 65.0216599,206.323587 C64.9216599,207.873587 64.7916599,209.233587 64.6016599,210.273587 C64.4216599,211.273587 63.9316599,213.853587 63.4416599,216.333587 C63.3216599,216.953587 63.8016599,217.523587 64.4316599,217.523587 L83.2816599,221.263587 C83.6816599,221.343587 84.0916599,221.163587 84.3116599,220.823587 C88.7816599,213.843587 96.6016599,209.213587 105.51166,209.213587 C114.45166,209.213587 122.31166,213.893587 126.77166,220.933587 C126.99166,221.273587 127.39166,221.463587 127.80166,221.383587 L147.81166,217.523587 C148.44166,217.523587 148.91166,216.953587 148.79166,216.333587 C148.30166,213.853587 147.81166,211.273587 147.63166,210.273587"
        />

        <path
          aria-label="genitals"
          className={getAreaClassName("genitals")}
          d="M128.66786,234.377688 C128.66786,247.168688 118.29886,257.537688 105.50786,257.537688 C92.7168599,257.537688 82.3478599,247.168688 82.3478599,234.377688 C82.3478599,221.586688 92.7168599,211.217688 105.50786,211.217688 C118.29886,211.217688 128.66786,221.586688 128.66786,234.377688"
        />

        <path
          aria-label="armRightUpper.front"
          className={getAreaClassName("armRightUpper.front")}
          d="M63.8316599,89.7335875 L63.8316599,123.163588 C63.8316599,123.573588 63.7516599,123.983588 63.5816599,124.363588 C62.3016599,127.233588 60.7116599,130.853588 59.3116599,133.993588 L39.6816599,128.563588 C41.5216599,124.103588 43.6016599,118.713588 45.2316599,114.583588 C47.4616599,108.963588 49.9516599,102.913588 53.3816599,95.5835875 C55.6816599,90.4635875 59.6516599,89.4235875 63.0916599,89.0735875 C63.4916599,89.0335875 63.8316599,89.3335875 63.8316599,89.7335875"
        />
        <path
          aria-label="armRightMiddle.front"
          className={getAreaClassName("armRightMiddle.front")}
          d="M58.4716599,135.873588 C57.3616599,138.373588 56.4816599,140.333588 56.1816599,140.973588 C54.1516599,145.283588 51.7716599,150.633588 49.2616599,156.313588 C48.1416599,158.813588 46.8116599,161.813588 45.4816599,164.813588 L26.5816599,159.593588 C27.6916599,156.553588 29.0716599,153.193588 29.8816599,151.513588 C32.7216599,146.393588 36.1416599,136.853588 38.8916599,130.463588 L58.4716599,135.873588 Z"
        />
        <path
          aria-label="armRightLower.front"
          className={getAreaClassName("armRightLower.front")}
          d="M44.6316599,166.693587 L44.6316599,166.703587 C43.5216599,169.163587 42.4416599,171.553587 41.4916599,173.583587 C38.7516599,179.403587 33.6016599,189.663587 32.0816599,193.913587 C32.0816599,193.913587 31.3316599,196.293587 30.6216599,198.543587 L18.3316599,195.143587 C19.5716599,190.523587 21.0916599,184.423587 21.5416599,182.133587 C22.1816599,178.923587 24.1716599,167.843587 25.0416599,164.303587 C25.2216599,163.563587 25.5216599,162.593587 25.8916599,161.513587 L44.6316599,166.693587 Z"
        />
        <path
          aria-label="handRight.front"
          className={getAreaClassName("handRight.front")}
          d="M30.0016599,200.483988 L17.7996599,197.113988 C17.6136599,197.782988 17.4416599,198.389988 17.2886599,198.910988 C15.6476599,200.301988 12.2136599,201.676988 10.1336599,203.104988 C7.51665987,204.901988 1.88965987,211.369988 0.324659868,212.467988 C-1.25534013,213.577988 3.32065987,215.030988 5.68165987,213.667988 C7.09665987,212.850988 10.0876599,210.557988 10.6436599,210.696988 C15.9046599,212.002988 20.8776599,210.273988 21.7306599,206.841988 L21.7306599,206.841988 C21.7806599,206.648988 22.0226599,206.541988 22.2726599,206.603988 C22.5236599,206.665988 22.6876599,206.873988 22.6406599,207.067988 L22.6406599,207.067988 C22.6406599,207.068988 22.6406599,207.068988 22.6406599,207.069988 C22.6406599,207.069988 22.6406599,207.069988 22.6406599,207.069988 C22.6406599,207.069988 22.6406599,207.070988 22.6406599,207.070988 C21.7076599,210.815988 16.4386599,212.748988 10.8016599,211.473988 C10.1026599,213.159988 9.61065987,216.750988 9.38165987,218.752988 C8.81365987,223.707988 8.39765987,228.917988 9.12565987,231.369988 C9.51765987,232.688988 10.4936599,233.541988 11.9876599,233.555988 C12.0606599,234.168988 12.7426599,235.361988 14.0126599,235.716988 C15.1366599,236.030988 16.4196599,235.265988 16.8076599,234.783988 C18.4716599,235.478988 19.7756599,235.255988 21.6726599,231.670988 C21.9576599,231.910988 22.2836599,232.006988 22.8696599,231.979988 C25.5116599,231.858988 27.7106599,222.589988 29.1416599,220.093988 C30.6046599,217.541988 31.8646599,212.770988 32.3016599,209.414988 C32.7166599,206.227988 29.7366599,203.495988 29.5326599,201.952988 C29.6466599,201.598988 29.8116599,201.082988 30.0016599,200.483988"
        />

        <path
          aria-label="armLeftUpper.front"
          className={getAreaClassName("armLeftUpper.front")}
          d="M172.55166,128.563588 L152.92166,133.993588 C151.53166,130.853588 149.92166,127.233588 148.65166,124.363588 C148.49166,123.983588 148.40166,123.573588 148.40166,123.163588 L148.40166,89.7335875 C148.40166,89.3335875 148.75166,89.0335875 149.14166,89.0735875 C152.58166,89.4235875 156.56166,90.4635875 158.86166,95.5835875 C162.28166,102.913588 164.77166,108.963588 167.01166,114.583588 C168.64166,118.713588 170.71166,124.103588 172.55166,128.563588"
        />
        <path
          aria-label="armLeftMiddle.front"
          className={getAreaClassName("armLeftMiddle.front")}
          d="M185.65166,159.593687 L166.76166,164.813687 C165.42166,161.813687 164.10166,158.813687 162.98166,156.313687 C160.46166,150.633687 158.09166,145.283687 156.06166,140.973687 C155.76166,140.333687 154.88166,138.373687 153.76166,135.873687 L173.34166,130.463687 C176.10166,136.853687 179.52166,146.393687 182.35166,151.513687 C183.16166,153.193687 184.54166,156.553687 185.65166,159.593687"
        />
        <path
          aria-label="armLeftLower.front"
          className={getAreaClassName("armLeftLower.front")}
          d="M193.98166,195.473687 L181.72166,198.863687 C180.98166,196.523687 180.16166,193.913687 180.16166,193.913687 C178.64166,189.663687 173.48166,179.403687 170.75166,173.583687 C169.80166,171.553687 168.71166,169.163687 167.61166,166.703687 L167.61166,166.693687 L186.34166,161.523687 C186.70166,162.603687 187.00166,163.563687 187.19166,164.303687 C188.07166,167.843687 190.05166,178.923687 190.69166,182.133687 C191.16166,184.483687 192.73166,190.823687 193.98166,195.473687"
        />
        <path
          aria-label="handLeft.front"
          className={getAreaClassName("handLeft.front")}
          d="M211.91246,212.468388 C210.34746,211.369388 204.72046,204.902388 202.10446,203.105388 C200.01846,201.673388 196.57146,200.295388 194.93546,198.900388 C194.80946,198.471388 194.66746,197.971388 194.51846,197.437388 L182.33646,200.802388 C182.48246,201.262388 182.61146,201.665388 182.70446,201.953388 C182.49946,203.497388 179.52046,206.228388 179.93546,209.414388 C180.37246,212.771388 181.63346,217.542388 183.09546,220.094388 C184.52646,222.590388 186.72546,231.858388 189.36746,231.980388 C189.95346,232.007388 190.27946,231.911388 190.56446,231.671388 C192.46146,235.255388 193.76546,235.479388 195.42946,234.784388 C195.81746,235.266388 197.10046,236.030388 198.22446,235.716388 C199.49446,235.362388 200.17646,234.168388 200.24946,233.556388 C201.74346,233.542388 202.71946,232.689388 203.11146,231.369388 C203.83946,228.917388 203.42346,223.708388 202.85546,218.752388 C202.62646,216.750388 202.13446,213.160388 201.43546,211.473388 C195.79846,212.749388 190.52946,210.816388 189.59746,207.071388 C189.59646,207.070388 189.59646,207.070388 189.59646,207.070388 C189.59646,207.070388 189.59646,207.070388 189.59646,207.069388 C189.59646,207.069388 189.59646,207.068388 189.59646,207.068388 L189.59646,207.068388 C189.54946,206.873388 189.71346,206.666388 189.96446,206.604388 C190.21446,206.542388 190.45646,206.648388 190.50646,206.842388 L190.50646,206.842388 C191.35946,210.274388 196.33346,212.003388 201.59346,210.696388 C202.15046,210.558388 205.14046,212.850388 206.55546,213.667388 C208.91646,215.031388 213.49246,213.577388 211.91246,212.468388"
        />

        <path
          aria-label="legLeftUpper.front"
          className={getAreaClassName("legLeftUpper.front")}
          d="M147.12166,283.623587 L112.06166,283.623587 C111.38166,277.543587 110.46166,269.603587 109.77166,263.173587 C109.66166,262.153587 109.56166,261.153587 109.46166,260.153587 C109.42166,259.643587 109.77166,259.183587 110.28166,259.083587 C121.89166,256.853587 130.67166,246.643587 130.67166,234.373587 C130.67166,230.903587 129.96166,227.603587 128.69166,224.593587 C128.51166,224.183587 128.75166,223.713587 129.18166,223.633587 L148.41166,219.753587 C148.93166,219.643587 149.45166,219.973587 149.58166,220.483587 C152.02166,230.173587 151.42166,238.773587 151.38166,243.073587 C151.34166,247.183587 150.27166,268.263587 149.38166,272.633587 C149.11166,273.843587 148.18166,278.383587 147.12166,283.623587"
        />
        <path
          aria-label="legLeftMiddle.front"
          className={getAreaClassName("legLeftMiddle.front")}
          d="M140.96166,347.893587 L115.61166,347.893587 C115.43166,343.363587 115.66166,338.653587 116.79166,331.613587 C117.81166,325.253587 115.35166,319.073587 114.55166,316.043587 C113.89166,313.533587 113.57166,309.843587 113.47166,305.743587 C113.40166,302.663587 113.21166,296.193587 112.88166,291.793587 C112.78166,290.513587 112.57166,288.343587 112.28166,285.663587 L146.71166,285.663587 C145.15166,293.373587 143.51166,301.843587 143.41166,303.333587 C143.34166,304.943587 143.27166,314.173587 143.79166,317.293587 C144.30166,320.413587 144.45166,324.213587 144.34166,326.143587 C144.05166,331.433587 143.42166,331.633587 142.38166,336.753587 C142.35166,336.903587 141.76166,341.533587 140.96166,347.893587"
        />
        <path
          aria-label="legLeftLower.front"
          className={getAreaClassName("legLeftLower.front")}
          d="M155.55166,403.733587 C156.58166,404.623587 156.48166,405.423587 156.00166,405.793587 C155.37166,406.293587 154.38166,405.843587 153.78166,405.483587 C154.39166,405.943587 155.23166,406.793587 154.38166,407.473587 C153.38166,408.283587 152.30166,407.593587 151.66166,407.003587 C152.26166,407.733587 152.98166,408.923587 151.88166,409.543587 C150.45166,410.333587 148.98166,409.553587 148.04166,408.793587 C148.62166,409.503587 149.08166,410.453587 148.25166,411.193587 C147.12166,412.203587 144.65166,412.483587 141.39166,409.713587 C141.05166,409.423587 139.30166,408.993587 136.69166,407.633587 C134.29166,406.383587 131.34166,403.483587 129.66166,402.723587 C128.66166,402.273587 125.06166,403.213587 122.57166,401.763587 C121.44166,401.103587 119.97166,399.963587 119.93166,397.023587 C119.88166,392.963587 122.38166,390.653587 122.25166,389.153587 C122.16166,388.103587 122.06166,387.063587 121.96166,386.033587 C120.22166,369.713587 116.23166,356.473587 115.91166,352.663587 C115.83166,351.733587 115.76166,350.833587 115.71166,349.933587 L140.70166,349.933587 C139.45166,359.763587 137.79166,372.693587 136.74166,379.913587 C136.42166,382.323587 136.50166,386.283587 136.50166,386.283587 C136.50166,386.283587 136.74166,386.953587 137.08166,387.773587 C137.18166,388.013587 137.29166,388.273587 137.41166,388.523587 C138.44166,390.743587 141.14166,391.713587 142.63166,392.503587 C145.19166,393.843587 152.99166,399.213587 153.97166,399.723587 C155.75166,400.673587 156.80166,401.553587 157.46166,402.493587 C158.15166,403.493587 157.24166,404.653587 155.55166,403.733587"
        />

        <path
          aria-label="legRightUpper.front"
          className={getAreaClassName("legRightUpper.front")}
          d="M102.67166,260.343688 C102.58166,261.283688 102.49166,262.213688 102.38166,263.173688 C101.69166,269.603688 100.77166,277.543688 100.09166,283.623688 L65.0416599,283.623688 C63.9716599,278.383688 63.0316599,273.843688 62.7716599,272.633688 C61.8916599,268.263688 60.8216599,247.183688 60.7816599,243.073688 C60.7316599,238.773688 60.1316599,230.173688 62.5716599,220.483688 C62.7016599,219.973688 63.2216599,219.643688 63.7416599,219.753688 L81.5516599,223.323688 C82.1616599,223.443688 82.5316599,224.103688 82.2916599,224.673688 C81.0416599,227.663688 80.3516599,230.943688 80.3516599,234.373688 C80.3516599,247.013688 89.6716599,257.473688 101.81166,259.263688 C102.34166,259.343688 102.72166,259.813688 102.67166,260.343688"
        />
        <path
          aria-label="legRightMiddle.front"
          className={getAreaClassName("legRightMiddle.front")}
          d="M99.8716599,285.663688 C99.5816599,288.343688 99.3716599,290.513688 99.2716599,291.793688 C98.9516599,296.193688 98.7616599,302.663688 98.6816599,305.743688 C98.5916599,309.843688 98.2616599,313.533688 97.6016599,316.043688 C96.8016599,319.073688 95.4016599,325.173688 95.3616599,331.613688 C95.3316599,337.333688 95.0316599,342.933688 94.6816599,347.893688 L69.4316599,347.893688 C69.2616599,347.103688 69.1216599,346.373688 68.9916599,345.713688 C67.5816599,338.303688 66.7916599,324.933688 68.3716599,317.293688 C69.5316599,311.653688 68.8116599,304.943688 68.7416599,303.333688 C68.6416599,301.843688 67.0016599,293.373688 65.4416599,285.663688 L99.8716599,285.663688 Z"
        />
        <path
          aria-label="legRightLower.front"
          className={getAreaClassName("legRightLower.front")}
          d="M69.8716599,349.933587 L94.5416599,349.933587 C94.4116599,351.623587 94.2816599,353.213587 94.1516599,354.693587 C93.8416599,358.403587 90.6716599,373.623587 90.0116599,385.833587 C89.9516599,386.973587 89.9116599,388.083587 89.9016599,389.153587 C89.8816599,390.663587 92.6316599,393.603587 92.2216599,397.023587 C92.0716599,398.293587 90.7516599,400.453587 89.5316599,401.573587 C88.3316599,402.673587 87.4616599,405.843587 86.9016599,407.763587 C86.3116599,409.803587 84.8516599,411.233587 84.5316599,412.303587 C83.4016599,416.033587 81.0516599,417.143587 79.0816599,416.493587 C78.2416599,416.223587 77.8416599,415.633587 77.6816599,414.993587 C77.5016599,414.313587 76.7216599,414.023587 76.1416599,414.423587 C75.3716599,414.953587 74.3516599,415.283587 73.1316599,414.853587 C72.6316599,414.673587 72.4016599,414.323587 72.3216599,413.913587 C72.2016599,413.283587 71.5216599,412.933587 70.9316599,413.203587 C70.4216599,413.423587 69.8816599,413.513587 69.3916599,413.253587 C67.9616599,412.473587 68.9816599,410.893587 69.3716599,410.353587 C68.9016599,410.873587 67.4416599,412.323587 66.1716599,411.683587 C64.4516599,410.803587 66.1216599,409.103587 66.6416599,408.393587 C65.2716599,410.163587 63.5616599,409.983587 63.3416599,409.423587 C62.8416599,408.133587 63.9416599,406.883587 64.6216599,406.063587 C65.8016599,404.623587 66.2516599,403.203587 67.0316599,402.303587 C67.7516599,401.463587 69.9816599,398.853587 69.9816599,398.853587 C69.9816599,398.853587 74.1216599,394.263587 74.5616599,393.483587 C75.0016599,392.713587 74.6716599,391.183587 74.6716599,389.973587 C74.6716599,389.453587 74.8416599,388.713587 75.0416599,388.023587 C75.2616599,387.323587 75.5016599,386.673587 75.6016599,386.413587 C75.6416599,386.323587 75.6616599,386.233587 75.6616599,386.143587 C75.7116599,385.623587 75.8216599,383.823587 75.5916599,381.723587 C74.2016599,369.093587 71.5016599,357.473587 69.8716599,349.933587"
        />
      </svg>
    );
  }

  return <div>Define type</div>;
};

import React, { useEffect } from "react";

import classNames from "classnames";
// import { default as _isEmpty } from "lodash/isEmpty";

import { getClassName } from "../../0-electrons/css";

import { BodySvg } from "./parts/BodySvg.tsx";

import * as css from "./Body.module.scss";
import * as cssTypo from "../TypographyCopy/TypographyCopy.module.scss";

export interface IBodyProps {
  type: "adult" | "child";
}

const Label = ({ children }) => (
  <p
    className={classNames(
      getClassName(cssTypo, "copy"),
      getClassName(cssTypo, "copy--size-small"),
      getClassName(cssTypo, "copy--color-grey"),
      getClassName(css, "BodyParts__label")
    )}
  >
    {children}
  </p>
);

export const Body: React.FC<IBodyProps> = ({ active, onClick, type }) => {
  const bodyParts =
    type === "adult"
      ? {
          front: (
            <BodySvg active={active} onClick={onClick} type="AdultFront" />
          ),
          back: <BodySvg active={active} onClick={onClick} type="AdultBack" />,
        }
      : type === "child"
      ? {
          front: (
            <BodySvg active={active} onClick={onClick} type="ChildFront" />
          ),
          back: <BodySvg active={active} onClick={onClick} type="ChildBack" />,
        }
      : {
          front: "Please define type",
          back: "Please define type",
        };

  return (
    <div className={getClassName(css, "BodyParts")}>
      <div className={getClassName(css, "BodyParts__item")}>
        {bodyParts.front}
        <Label>Frontansicht</Label>
      </div>
      <div className={getClassName(css, "BodyParts__item")}>
        {bodyParts.back}
        <Label>Rückansicht</Label>
      </div>
    </div>
  );
};

export default Body;

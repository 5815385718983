/**
 * Get Color from Gradient & Position
 *
 * see discussion at @url https://stackoverflow.com/questions/30143082/how-to-get-color-value-from-gradient-by-percentage-with-javascript
 * and code example @url https://jsfiddle.net/ctk916xa/2/
 *
 */

export class LinearGradientHelper {
  context = null;

  constructor(gradientColors) {
    const WIDTH = 101; // 0 to 100
    const HEIGHT = 1;

    // [ [color, % ie 0, 0.5, 1], [ ... ], ... ]
    // Canvas
    const canvasElement = document.createElement("CANVAS");
    canvasElement.width = WIDTH;
    canvasElement.height = HEIGHT;

    this.context = canvasElement.getContext("2d");

    // Gradient
    const gradient = this.context.createLinearGradient(0, 0, WIDTH, 0); // x0, y0, x1, y1

    gradientColors.forEach(val => {
      gradient.addColorStop(val[1], val[0]);
    });

    // Fill with gradient
    this.context.fillStyle = gradient;
    this.context.fillRect(0, 0, WIDTH, HEIGHT); // x, y, width, height

    /* debug features: show generated gradient */
    // canvasElement.style.width = "100%";
    // document.body.appendChild(canvasElement);
  }

  getColor(
    percent // percent [0..100]
  ) {
    const color = this.context.getImageData(parseInt(percent), 0, 1, 1); // x, y, width, height
    const rgba = color.data;

    return `rgb(${rgba[0]}, ${rgba[1]}, ${rgba[2]})`;
  }
}

/**
 * Usage Example
 */

// background: linear-gradient(90deg, #386657 0%, #85B87A 24.48%, #FFE600 51.56%, #BA4F1A 80.21%, #940023 100%);
// const grad = new LinearGradientHelper([
//   ["#386657", 0],
//   ["#85B87A", 0.2448],
//   ["#FFE600", 0.5156],
//   ["#BA4F1A", 0.8021],
//   ["#940023", 1],
// ]);

// console.log(grad.getColor(0));
// console.log(grad.getColor(0.1));
// console.log(grad.getColor(0.4));
// console.log(grad.getColor(0.95));
// console.log(grad.getColor(1));

// result examples for storybook
// used by stories for QuizResult and Quiz
import React, { Suspense } from "react";

import { Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";

import { useLocation } from "@reach/router";

import TypographyCopy from "../components/1-atoms/TypographyCopy/TypographyCopy";
import TeaserBig from "../components/2-molecules/TeaserBig/TeaserBig";
import { QuizProps } from "../components/3-organisms/Quiz/Quiz";

const getImage = (collection, name) =>
  collection.find(item => item.name === name);

const createImage = (
  images: Array,
  type: string,
  skin: string,
  intensity: number | string
) => {
  const imageNode = getImage(images, `${type}-${skin}-${intensity}`);
  const alt = `${type} ${skin} ${intensity}`;

  return imageNode ? (
    <GatsbyImage image={imageNode.childImageSharp?.gatsbyImageData} alt={alt} />
  ) : (
    <img
      src={`https://via.placeholder.com/160x160?text=${type}+${skin}+${intensity}`}
      alt={alt}
    />
  );
};

const createSkinAnswerOptions = (
  images: Array,
  type: string,
  count: number
) => {
  const answers = [];

  for (let i = 0; i < count; i++) {
    answers.push({
      label: `Stärke ${i}`,
      value: i,
      image: {
        light: createImage(images, type, "light", i),
        medium: createImage(images, type, "medium", i),
        dark: createImage(images, type, "dark", i),
      },
    });
  }

  return answers;
};
const getQrCodeUrl = ({ personType, score }) => {
  const location = useLocation();

  return `${location.protocol}//${location.host}${location.pathname}?type=${personType}&score=${score}`;
};

const ScoradResultLazy = React.lazy(
  () => import("../components/2-molecules/ScoradResult/ScoradResult")
);

const getResultButton = ({
  score = 0,
  scoreColor,
  personType,
  resultLabel,
  resultDescription,
}) => {
  const isSSR = typeof window === "undefined";

  return isSSR ? null : (
    <Suspense fallback={<div style={{ display: "none" }}>Lade Ergebnis …</div>}>
      <ScoradResultLazy
        buttonLabel="Ergebnis zusätzlich herunterladen"
        headline="Dein Hauttest Ergebnis"
        resultGradientStops={[
          "0",
          "rgba(52, 220, 42, 1)",
          "0.5",
          "rgba(212, 213, 9, 1)",
          "1",
          "rgba(220, 83, 42, 1)",
        ]}
        resultScore={score}
        resultScoreColor={scoreColor}
        resultLabel={resultLabel}
        resultDescription={resultDescription}
        footerLine1={
          "Scanne diesen QR-Code in der Foto-App auf deinem Smartphone,\num weitere Informationen zu deinem Testergebnis zu erhalten."
        }
        footerLine2={
          "Dieser Test basiert auf dem klinischen\nBewertungssystem SCORAD (SCORing Atopic Dermatitis)."
        }
        qrContent={getQrCodeUrl({ personType, score })}
      />
    </Suspense>
  );
};

const start: QuizProps["start"] = {
  introImage: (
    <StaticImage
      quality={100}
      src="../images/skin-quiz/hauttest-scorad-intro.jpg"
      width={920}
      height={300}
      alt="Hauttest"
    />
  ),
  introHeadline: "Hauttest",
  headline: "In welcher Phase befindet sich meine Haut?",
  copy: [
    <TypographyCopy size="big-responsive" color="grey" key="copy-1">
      Neurodermitis verläuft in Schüben – symptomfreie Intervalle und akute
      Phasen wechseln sich ab. Die gezielte Neurodermitis-Behandlung hilft dir,
      akute Symptome zu lindern und deine beschwerdefreien Intervalle verlängern
      zu können.
    </TypographyCopy>,
    <TypographyCopy size="big-responsive" color="grey" key="copy-2">
      Wenn du herausfinden möchtest, in welcher Phase du dich (oder die
      betroffene Person) befindest und wie stark die Ausprägung deiner
      Neurodermitis ist, hilft dir unser Hauttest sicher weiter. Außerdem
      erfährst du, welche Pflege sich nun anbietet.
    </TypographyCopy>,
    <TypographyCopy size="big-responsive" color="grey" key="copy-3">
      Bitte beachte, dass dir der Hauttest eine erste Einschätzung des
      Hautzustandes gibt. Für eine konkrete Diagnose empfehlen wir immer eine:n
      Dermatolog:in aufzusuchen.
    </TypographyCopy>,
    <TypographyCopy size="small" color="grey" key="copy-4">
      Der Test basiert auf dem klinischen Bewertungssystem SCORAD (SCORing
      Atopic Dermatitis).
    </TypographyCopy>,
  ],
  startLabel: "Jetzt Test starten",
};

const questions = images => {
  return {
    personType: {
      question: "Allgemeines",
      copy: "Bitte gib an, ob die betroffene Person jünger oder älter als 24 Monate ist.",
      answerOptions: [
        {
          label: "Bis 23 Monate",
          value: "child",
        },
        {
          label: "24 Monate und älter",
          value: "adult",
        },
      ],
    },
    personSkin: {
      question: "Allgemeines",
      copy: "Welche der nachfolgenden Kategorien kommt dem Hautton der betroffenen Person am nächsten?",
      answerOptions: [
        {
          label: "Hautton Hell",
          value: "light",
          color: "#FFE6DF",
        },
        {
          label: "Hautton Medium",
          value: "medium",
          color: "#CF9D76",
        },
        {
          label: "Hautton Dunkel",
          value: "dark",
          color: "#321906",
        },
      ],
    },
    area: {
      question: "Betroffene Hautstellen",
      copy: {
        adult:
          "Bitte wähle alle betroffenen Körperregionen aus. Diese kannst du durch ein Klicken auf die entsprechende Stelle markieren. Eine Mehrfachauswahl ist möglich.",
        child:
          "Bitte wähle aus, welche Körperregionen des Kindes betroffen sind. Diese kannst du durch ein Klicken auf die entsprechende Stelle markieren. Eine Mehrfachauswahl ist möglich.",
      },
      type: "slot",
    },
    intensityCrusting: {
      question: "Nässen und Krustenbildung",
      copy: (
        <>
          Bitte beurteile das Nässen und die Krustenbildung der Haut an einer
          Stelle, die <strong>von einem Ekzem betroffen</strong> ist. Du hast
          anhand von Beispielbildern die Möglichkeit, den Zustand der Haut
          einzuschätzen.
        </>
      ),
      answerOptions: createSkinAnswerOptions(images, "crusting", 4),
    },
    intensityDryness: {
      question: "Trockene Haut",
      copy: (
        <>
          Bitte beurteile die Trockenheit deiner Haut an einer Stelle, die{" "}
          <strong>nicht von einem Ekzem betroffen</strong> ist. Du hast anhand
          von Beispielbildern die Möglichkeit, den Zustand der Haut
          einzuschätzen.
        </>
      ),
      answerOptions: createSkinAnswerOptions(images, "dryness", 4),
    },
    intensityRedness: {
      question: "Rötung",
      copy: (
        <>
          Bitte beurteile die Rötung der Haut an einer Stelle,{" "}
          <strong>die von einem Ekzem betroffen</strong> ist. Du hast anhand von
          Beispielbildern die Möglichkeit, den Zustand der Haut einzuschätzen.
        </>
      ),
      answerOptions: createSkinAnswerOptions(images, "redness", 4),
    },
    intensitySwelling: {
      question: "Schwellung",
      copy: (
        <>
          Bitte beurteile die Rötung der Haut an einer Stelle, die{" "}
          <strong>von einem Ekzem betroffen</strong> ist. Du hast anhand von
          Beispielbildern die Möglichkeit, den Zustand der Haut einzuschätzen.
        </>
      ),
      answerOptions: createSkinAnswerOptions(images, "swelling", 4),
    },
    intensityScratchMarks: {
      question: "Kratzwunden",
      copy: (
        <>
          Bitte beurteile die Kratzwunden auf der Haut an einer Stelle, die{" "}
          <strong>von einem Ekzem betroffen</strong> ist. Du hast anhand von
          Beispielbildern die Möglichkeit, den Zustand der Haut einzuschätzen.
        </>
      ),
      answerOptions: createSkinAnswerOptions(images, "scratch-marks", 4),
    },
    intensitySkinThickening: {
      question: "Hautverdickung",
      copy: (
        <>
          Bitte beurteile die Verdickung der Haut an einer Stelle, die{" "}
          <strong>von einem Ekzem betroffen</strong> ist. Du hast anhand von
          Beispielbildern die Möglichkeit, den Zustand der Haut einzuschätzen.
        </>
      ),
      answerOptions: createSkinAnswerOptions(images, "skin-thickening", 4),
    },
    subjectiveItching: {
      question: "Subjektive Symptome: Juckreiz",
      copy: (
        <>
          Gib bitte an, wie sehr dich (oder die betroffene Person){" "}
          <strong>Juckreiz</strong> aktuell einschränkt. Gib dies, wenn möglich,
          als Durchschnitt der letzten 2 Tage an.
        </>
      ),
      type: "range",
      min: 0,
      max: 10,
      step: 1,
      mapping: [
        {
          value: 0,
          label: "Kein Juckreiz",
        },
        {
          value: 1,
          label: "Leichter Juckreiz",
        },
        {
          value: 5,
          label: "Mittelschwerer Juckreiz",
        },
        {
          value: 7,
          label: "Belastender Juckreiz",
        },
        {
          value: 10,
          label: "Unerträglicher Juckreiz",
        },
      ],
    },
    subjectiveSleeplessness: {
      question: "Subjektive Symptome: Schlafstörungen",
      copy: (
        <>
          Gib bitte zuletzt an, wie sehr dich (oder die betroffene Person){" "}
          <strong>Schlafstörungen</strong> aktuell einschränken. Gib dies, wenn
          möglich, als Durchschnitt der letzten 2 Tage an.
        </>
      ),
      type: "range",
      min: 0,
      max: 10,
      step: 1,
      mapping: [
        {
          value: 0,
          label: "Keine Schlafstörungen",
        },
        {
          value: 1,
          label: "Leichte Schlafstörungen",
        },
        {
          value: 5,
          label: "Mittelschwere Schlafstörungen",
        },
        {
          value: 7,
          label: "Belastende Schlafstörungen",
        },
        {
          value: 10,
          label: "Schwere Schlafstörungen",
        },
      ],
    },
  };
};

const results: QuizProps["results"] = {
  introHeadline: "Dein Hauttest Ergebnis",
  introImage: (
    <StaticImage
      quality={100}
      src="../images/skin-quiz/hauttest-ergebnis.png"
      width={920}
      height={300}
      alt="Hauttest"
    />
  ),
  level_0: {
    headline: "Das Ergebnis: Mild (Kein Schub, sehr trockene Haut)",
    children: ({ personType }) => (
      <>
        {personType === "child" ? (
          <p>
            Das Kind scheint sich aktuell in einer erscheinungsfreien Phase der
            Neurodermitis zu befinden oder hat mit sehr trockener und
            empfindlicher Haut zu kämpfen. Gerade jetzt ist es besonders
            wichtig, die Haut mit der richtigen Pflege zu versorgen, um ihr die
            nötige Feuchtigkeit zuzuführen. Der Schutz durch tägliche Pflege
            hilft aber auch, Schübe zu reduzieren, zu mildern oder gar zu
            verhindern.
          </p>
        ) : (
          <p>
            Du (oder die betroffene Person) hast mit sehr trockener und
            empfindlicher Haut zu kämpfen oder scheinst dich aktuell in einer
            schubfreien Phase der Neurodermitis zu befinden. Gerade jetzt ist es
            besonders wichtig, die Haut mit der richtigen Pflege zu versorgen,
            um ihr die nötige Feuchtigkeit zuzuführen. Der Schutz durch tägliche
            Pflege hilft, Schübe zu reduzieren, zu mildern oder gar zu
            verhindern.
          </p>
        )}

        <p>
          Bitte beachte, dass dies nur eine erste Einschätzung ist. Wir
          empfehlen dir, die konkrete Diagnose immer bei einem:einer
          Dermatolog:in abklären zu lassen.
        </p>
        <p>
          Wenn du erfahren möchtest, welche Produkte helfen können, scrolle
          jetzt weiter und sieh dir unsere Empfehlungen an.
        </p>
        <p>Du kannst das Ergebnis auch gleich herunterladen.</p>
        <br />

        <h2>Empfehlung:</h2>

        <p>
          Auch in schubfreien Phasen hat die Haut mit dem Barrieredefekt zu
          kämpfen, wodurch ihr wichtige Feuchthaltefaktoren und Hautfette
          fehlen. Deshalb ist sie häufig trocken und reagiert sehr empfindlich
          auf verschiedene Faktoren.
        </p>

        <p>
          Die Basistherapie, also die tägliche Pflege, ist dabei eine wichtige
          Strategie, um die Haut zu unterstützen und sie möglichst lange vor
          einem neuen Schub zu schützen.
        </p>

        <p>
          Die Reinigungs- und Pflegeprodukte, die dabei verwendet werden,
          sollten seifenfrei (pH-neutral), rückfettend und feuchtigkeitsspendend
          sein und ohne Allergene auskommen.
        </p>
      </>
    ),
    teasers: {
      headline: "Wir empfehlen dafür folgendes Produkt:",
      items: [
        {
          copy: (
            <>
              <h2>DEXERYL®</h2>
              <p>
                DEXERYL® Creme ist ein Medizinprodukt mit fundierter Studienlage
                und hilft dabei, die Haut täglich zu pflegen und zu schützen.
                Sie bildet einen schützenden Film, der rückfettend wirkt und so
                Entspannung für trockene und zu Neurodermitis neigender Haut
                bringt.
              </p>
              <p>
                Auch bereits beginnender Juckreiz lässt sich so lindern.
                DEXERYL® Creme enthält drei Wirkstoffe und verzichtet bewusst
                auf Duftstoffe und Lanolin (Wollwachs), um die Haut nicht
                zusätzlich zu reizen.
              </p>
              <p>
                Weitere hilfreiche Informationen zur DEXERYL® Creme erhältst du
                hier:
              </p>
            </>
          ),
          image: (
            <StaticImage
              quality={100}
              src="../images/skin-quiz/pierre-fabre-dexeryl.png"
              width={700}
              alt="DEXERYL® Pflegecreme"
            />
          ),
          href: "https://pierrefabre-dermatologie.de/unsere-produkte/dexeryl/behandlung/?utm_source=hyperlink&utm_medium=hautstark&utm_content=keine+Schuebe",
          label: "Mehr Produktinformationen",
        },
      ],
    },
    slotFooter: ({ personType, resultColor, resultScore }) => {
      return (
        <>
          {getResultButton({
            score: resultScore,
            scoreColor: resultColor,
            resultLabel: "Mild",
            resultDescription: "(Kein Schub, sehr trockene Haut)",
            personType,
          })}

          <p>
            Auf unserer Webseite findest du weitere Informationen und hilfreiche
            Tipps zu dieser Neurodermitis-Phase.
          </p>
        </>
      );
    },
    teaserBig: (
      <TeaserBig
        theme="dark"
        headline="Keine Schübe, sehr trockene Haut?"
        button={
          <Link to="/neurodermitis/verlauf/keine-schuebe/">Mehr erfahren</Link>
        }
        image={
          <StaticImage
            src="../images/frauen-haende-creme.jpg"
            placeholder="none"
            alt="Frau cremed sich die Hände ein"
            width={1440}
            quality={90}
          />
        }
        teaserPosition="left"
      >
        Mit Neurodermitis ist deine Haut häufig enorm trocken und empfindlich –
        auch in erscheinungsfreien Phasen. Das liegt daran, dass ihr wichtige
        Feuchthaltefaktoren und Hautfette fehlen. Auch die Hautflora …
      </TeaserBig>
    ),
  },
  level_1: {
    headline: "Das Ergebnis: Moderat (Leichter Schub, Juckreiz)",
    children: ({ personType }) => (
      <>
        {personType === "child" ? (
          <p>
            Die Haut des Kindes juckt, es sind aber noch keine Ekzeme zu
            erkennen? Dann scheint es gerade mit einem leichten Schub der
            Neurodermitis zu kämpfen zu haben. Nun ist es wichtig, den Juckreiz
            zu lindern, damit er nicht weiter belastet und zum Kratzen
            verleitet.
          </p>
        ) : (
          <p>
            Die Haut juckt, es sind aber noch keine Ekzeme zu erkennen? Dann
            scheinst du (oder die betroffene Person) gerade mit einem leichten
            Schub der Neurodermitis zu kämpfen zu haben. Nun ist es wichtig, den
            Juckreiz zu lindern, damit er nicht weiter belastet und zum Kratzen
            verleitet.
          </p>
        )}

        <p>
          Bitte beachte, dass dies nur eine erste Einschätzung ist. Wir
          empfehlen dir, die konkrete Diagnose immer bei einem:einer
          Dermatolog:in abklären zu lassen.
        </p>
        <p>
          Wenn du erfahren möchtest, welche Produkte helfen können, scrolle
          jetzt weiter und sieh dir unsere Empfehlungen an.
        </p>

        <p>Du kannst das Ergebnis auch gleich herunterladen.</p>
        <br />

        <h2>Empfehlung:</h2>

        <p>
          Auch, wenn noch keine Ekzeme erkennbar sind, kann die Haut entzündet
          sein. Diese unsichtbare, aber nachweisbare Entzündung nennt sich
          Mikroinflammation. Das zeigt sich durch den anhaltenden, plagenden
          Juckreiz. Durch das Kratzen entstehen kleine Risse in der Haut, die
          sie noch anfälliger für Keime und Bakterien machen.
          <br />
          Deshalb ist es jetzt besonders wichtig, die Entzündung zu mildern, um
          heftigere Schübe zu verhindern.
        </p>

        <p>
          Die Pflegeprodukte sollten dabei Aktivstoffe (auch bekannt als
          Emollienzien Plus) enthalten. Außerdem sind juckreizlindernde
          Inhaltsstoffe und eine hochverträgliche Pflege wichtig, um Allergien
          zu vermeiden.
        </p>
      </>
    ),
    teasers: {
      headline: "Wir empfehlen dir dafür folgende Produkte:",
      items: [
        {
          copy: (
            <>
              <h2>XeraCalm A.D Pflegeserie (Avène)</h2>
              <p>
                Die XeraCalm A.D Pflegeserie entfaltet bei der Pflege von
                trockener und zu Neurodermitis neigender Haut eine
                Dreifachwirkung: gegen Juckreiz, eventuelle Entzündungen und
                bakterielle Superinfektionen. Dank innovativer Verschlusstechnik
                können wir bei der XeraCalm A.D Rückfettenden Creme, dem
                XeraCalm A.D Rückfettenden Balsam und dem XeraCalm A.D
                Anti-Juckreiz Konzentrat auf Konservierungsstoffe verzichten,
                sodass die Haut nicht zusätzlich gereizt wird. Außerdem sind
                diese Produkte frei von Duftstoffen und enthalten keinen
                Alkohol.
              </p>
              <p>
                Die XeraCalm A.D Rückfettende Creme pflegt die Haut, beruhigt
                sie und lindert Hautreizungen.
              </p>
              <p>
                Der XeraCalm A.D Rückfettende Balsam stärkt die trockene, zu
                Rissen neigende Haut und stellt die Hautbarriere wieder her.
              </p>
              <p>
                Das XeraCalm A.D Anti-Juckreiz Konzentrat beruhigt die
                trockenen, zu starkem Juckreiz und atopischen Ekzemen neigenden
                Stellen sofort.
              </p>
              <p>
                Das XeraCalm A.D Rückfettende Reinigungsöl reinigt die Haut
                sanft, ohne sie auszutrocknen. Rückfettende Hautsubstanzen
                stellen die Hautbarriere wieder her.
              </p>
            </>
          ),
          image: (
            <StaticImage
              quality={100}
              src="../images/skin-quiz/avene-xeracalm-ad-serie.png"
              width={700}
              alt="Avène Eau Thermale XeraCalm A.D"
            />
          ),
          href: "https://www.eau-thermale-avene.de/sortiment/xeracalm-ad?utm_source=hyperlink&utm_medium=hautstark&utm_content=leichte+Schuebe",
          label: "Mehr Produktinformationen",
        },
        {
          copy: (
            <>
              <h2>EXOMEGA CONTROL (A-Derma)</h2>
              <p>
                Die entzündungshemmende Pflegeserie mit Arzneipflanzenkraft
                EXOMEGA CONTROL von A-Derma unterstützt die Haut durch ihre
                beruhigende und immunregulierende Wirkung, die
                Mikroinflammationen unter Kontrolle hält. Zusätzlich kann sie
                dabei unterstützen, die natürliche Hautbarriere zu regenerieren.
                Sie wirkt besonders lindernd auf Entzündungen und
                wiederkehrenden Juckreiz.
              </p>
              <p>
                In der Serie enthalten sind unter anderem die EXOMEGA CONTROL
                Geschmeidigmachende Creme und die EXOMEGA CONTROL
                Intensiv-Körpermilch, die speziell bei Hautirritationen und
                Mikroinflammationen unterstützen, sowie das hautberuhigende
                Pflegebad in akuten Schub-Phasen.
              </p>
            </>
          ),
          image: (
            <StaticImage
              quality={100}
              src="../images/skin-quiz/aderma-exomega-control-serie.png"
              width={700}
              alt="ADERMA EXOMEGA CONTROL"
            />
          ),
          href: "https://www.aderma.de/f/koerperpflege/hautpflege-fuer-trockene-und-sehr-trockene-haut?utm_source=hyperlink&utm_medium=hautstark&utm_content=leichte+Schuebe",
          label: "Mehr Produktinformationen",
        },
      ],
    },
    slotFooter: ({ personType, resultColor, resultScore }) => {
      return (
        <>
          {getResultButton({
            score: resultScore,
            scoreColor: resultColor,
            resultLabel: "Moderat",
            resultDescription: "(Leichter Schub, Juckreiz)",
            personType,
          })}

          <p>
            Auf unserer Webseite findest du weitere Informationen und hilfreiche
            Tipps zu leichten Schüben.
          </p>
        </>
      );
    },
    teaserBig: (
      <TeaserBig
        theme="dark"
        headline="Leichte Schübe, Juckreiz?"
        button={
          <Link to="/neurodermitis/verlauf/leichte-schuebe/">
            Mehr erfahren
          </Link>
        }
        image={
          <StaticImage
            src="../images/frauen-haende-creme.jpg"
            placeholder="none"
            alt="Frau cremed sich die Hände ein"
            width={1440}
            quality={90}
          />
        }
        teaserPosition="left"
      >
        Wenn deine Haut juckt, aber noch keine Ekzeme sichtbar sind, heißt das
        nicht, dass sie nicht entzündet ist. Diese unsichtbare, aber
        nachweisbare Entzündung nennt sich Mikroinflammation …
      </TeaserBig>
    ),
  },
  level_2: {
    headline: "Das Ergebnis: Schwer (Mittelschwerer Schub, Ekzem)",
    children: ({ personType }) => (
      <>
        {personType === "child" ? (
          <p>
            Wenn bereits deutliche Ekzeme mit Bläschenbildung bis hin zu offenen
            Hautstellen auftreten, solltest du unbedingt ärztlichen Rat
            hinzuziehen. Gemeinsam lässt sich so die passende Therapieform mit
            topischen Arzneimitteln finden.
          </p>
        ) : (
          <p>
            Wenn du (oder die betroffene Person) bereits mit deutlichen Ekzemen
            mit Bläschenbildung bis hin zu offenen Hautstellen zu kämpfen hast,
            solltest du unbedingt ärztlichen Rat hinzuziehen und die konkrete
            Diagnose immer bei einem:einer Dermatolog:in abklären lassen.
            Gemeinsam lässt sich so die passende Therapieform z.B. mit topischen
            Arzneimitteln finden.
          </p>
        )}
        <p>
          Bitte beachte, dass dies nur eine erste Einschätzung ist. Wir
          empfehlen, die konkrete Diagnose immer bei einem:einer Dermatolog:in
          abklären zu lassen.
        </p>
        <p>
          Unterstützend zu verschreibungspflichtigen Cremes oder Salben ist auch
          die Verwendung medizinischer Hautpflege wichtig. Wenn du erfahren
          möchtest, welche Produkte hier unterstützen können, scrolle jetzt
          weiter und sieh dir unsere Empfehlungen an.
        </p>

        <p>Du kannst das Ergebnis auch gleich herunterladen.</p>
        <br />

        <h2>Empfehlung:</h2>

        <p>
          Bei einem mittelschweren Schub ist es unbedingt ratsam, gemeinsam mit
          einem:einer Ärzt:in über eine Therapie mit topischen Arzneimitteln zu
          sprechen.
        </p>

        <p>
          Du kannst die Haut zusätzlich mit der begleitenden Verwendung von
          medizinischer Hautpflege schützen. Der Vorteil dabei: Sie lassen sich
          im Vergleich zu verschreibungspflichtigen Produkten langfristig
          anwenden und helfen der Haut dabei, wieder ins Gleichgewicht zu kommen
          und Schübe hinauszuzögern.
        </p>
      </>
    ),
    teasers: {
      headline: "Wir empfehlen dafür folgende Produkte:",
      items: [
        {
          copy: (
            <>
              <h2>Dexyane MeD Reparierende beruhigende Creme</h2>
              <p>
                Die Dexyane MeD Reparierende beruhigende Creme legt sich mit
                ihrer ultra-feuchtigkeits-spendenden Textur wie ein
                atmungsaktives Pflaster auf die Haut. Dadurch kann sie die
                angegriffene Oberfläche beruhigen, reparieren und das
                Wiederauftreten von Ekzemen begrenzen. Die Creme wirkt
                juckreizstillend und ist auch auf offenen Stellen anwendbar.
              </p>
              <p>
                Weitere hilfreiche Informationen zur Dexyane MeD Reparierende
                beruhigende Creme erhältst du hier.
              </p>
            </>
          ),
          image: (
            <StaticImage
              quality={100}
              src="../images/skin-quiz/ducray-dexyane-med-creme.png"
              width={700}
              alt="Dexyane MeD Reparierende beruhigende Creme"
            />
          ),
          href: "https://www.ducray.com/de-de/dexyane-med/dexyane-med-reparierende-beruhigende-creme?utm_source=hyperlink&utm_medium=hautstark&utm_content=mittelschwere+Schuebe",
          label: "Mehr Produktinformationen",
        },
        {
          copy: (
            <>
              <h2>Dexyane MeD Augenlidcreme</h2>
              <p>
                Die Dexyane MeD Augenlidcreme behandelt und begrenzt das
                Wiederauftreten von Ekzemläsionen an den Augenlidern. Die Creme
                bildet einen Schutzfilm auf der Haut und spendet so
                Feuchtigkeit, lindert den Juckreiz und sorgt für Abschwellung.
                Außerdem werden Rötungen reduziert und die geschädigte
                Hautbarriere wieder hergestellt.
              </p>
              <p>
                Weitere hilfreiche Informationen zur Dexyane MeD Augenlidcreme
                erhältst du hier.
              </p>
            </>
          ),
          image: (
            <StaticImage
              quality={100}
              src="../images/skin-quiz/ducray-dexyane-med-augenlidcreme.png"
              width={700}
              alt="Dexyane MeD Augenlidcreme"
            />
          ),
          href: "https://www.ducray.com/de-de/dexyane-med/dexyane-med-augenlidcreme?utm_source=hyperlink&utm_medium=hautstark&utm_content=mittelschwere+Schuebe",
          label: "Mehr Produktinformationen",
        },
        {
          copy: (
            <>
              <h2>Dexyane (Ducray)</h2>
              <p>
                Die Dexyane Hautpflegeserie von Ducray bietet eine Rundumlösung
                für sehr trockene und atopische Haut. Die Dexyane MeD Creme
                sorgt schon in der Akutphase für Linderung, der Balsam hilft
                gegen Juckreiz, repariert und stärkt täglich die Hautbarriere.
                Das Reinigungsgel reinigt die Haut sanft.
              </p>
              <p>
                Weitere hilfreiche Informationen zur Dexyane Hautpflegeserie
                erhältst du hier.
              </p>
            </>
          ),
          image: (
            <StaticImage
              quality={100}
              src="../images/skin-quiz/ducray-dexyane-med-serie.png"
              width={700}
              alt="Dexyane (Ducray)"
            />
          ),
          href: "https://www.ducray.com/de-de/pflege/haut/dexyane?utm_source=hyperlink&utm_medium=hautstark&utm_content=mittelschwere+Schuebe",
          label: "Mehr Produktinformationen",
        },
      ],
    },
    slotFooter: ({ personType, resultColor, resultScore }) => {
      return (
        <>
          {getResultButton({
            score: resultScore,
            scoreColor: resultColor,
            resultLabel: "Schwer",
            resultDescription: "(Mittelschwerer Schub, Ekzem)",
            personType,
          })}

          <p>
            Auf unserer Webseite findest du weitere Informationen und hilfreiche
            Tipps zu mittelschweren Schüben.
          </p>
        </>
      );
    },
    teaserBig: (
      <TeaserBig
        theme="dark"
        headline="Mittelschwere Schübe, Ekzeme?"
        button={
          <Link to="/neurodermitis/verlauf/mittelschwere-schuebe/">
            Mehr erfahren
          </Link>
        }
        image={
          <StaticImage
            src="../images/frauen-haende-creme.jpg"
            placeholder="none"
            alt="Frau cremed sich die Hände ein"
            width={1440}
            quality={90}
          />
        }
        teaserPosition="left"
      >
        Bei deutlichen Ekzemen und mitunter offenen Hautstellen, ist eine
        Therapie mit topischen Arzneimitteln ratsam. Zieh hierfür unbedingt
        ärztlichen Rat hinzu, um die für dich richtige Therapieform zu finden.
      </TeaserBig>
    ),
  },
};

export { start, questions, results };

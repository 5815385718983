import React from "react";

import { getClassName } from "../../../0-electrons/css";
import Clone from "../../../0-electrons/Clone/Clone";
import TypographyHeadline from "../../../1-atoms/TypographyHeadline/TypographyHeadline";
import Button from "../../../1-atoms/Button/Button";
import QuizIntro from "../../../2-molecules/Quiz/Intro/QuizIntro";

import * as css from "./QuizStart.module.scss";

export interface QuizStartProps {
  /* The Element/Node to use as the slot for the image */
  introImage: React.ReactElement;
  /* The Big Headline inside of the image */
  introHeadline?: string;
  /** The headline for the intro part */
  headline?: string;
  /** the children to use for copy/text */
  copy: Array<React.ReactNode>;
  /** The function for the start */
  onStart?: () => void;
  /** The label for the start button */
  startLabel?: string;
}

const QuizStart: React.FC<QuizStartProps> = ({
  introImage,
  introHeadline,
  headline,
  copy,
  onStart = () => console.warn("QuizStart: no onStart action defined"),
  startLabel,
}: QuizStartProps) => {
  return (
    <div className={getClassName(css, "QuizStart")}>
      <QuizIntro headline={introHeadline} image={introImage} />
      <div className={getClassName(css, "QuizStart__container")}>
        <TypographyHeadline
          size="h2"
          className={getClassName(css, "QuizStart__headline")}
          tag="h2"
        >
          {headline}
        </TypographyHeadline>

        {Array.isArray(copy) && copy.length > 0
          ? copy.map((copyItem, index) => (
              <Clone
                node={copyItem}
                className={getClassName(css, "QuizStart__copy")}
                key={`copy-${index}`}
              />
            ))
          : null}

        <Button
          className={getClassName(css, "QuizStart__button")}
          type="primary"
          tag="button"
          onClick={onStart}
        >
          {startLabel}
        </Button>
      </div>
    </div>
  );
};
export default QuizStart;

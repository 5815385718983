import React, { useState } from "react";

import classNames from "classnames";

import { getClassName } from "../../0-electrons/css";
import Clone from "../../0-electrons/Clone/Clone";
import Button from "../../1-atoms/Button/Button";
import Swatch from "../../1-atoms/Swatch/Swatch";
import TypographyHeadline from "../../1-atoms/TypographyHeadline/TypographyHeadline";
import TypographyCopy from "../../1-atoms/TypographyCopy/TypographyCopy";
import RangeSlider from "../../2-molecules/RangeSlider/RangeSlider";

import * as buttonCss from "../../1-atoms/Button/Button.module.scss";
import * as css from "./Question.module.scss";

export interface QuestionAnswerProps {
  label: string;
  value: string | number | boolean;
  active?: boolean;
  image?: React.ReactElement;
  color?: string;
}

interface QuestionItemBaseProps {
  question: string;
  copy: string;
}
export interface QuestionItemProps extends QuestionItemBaseProps {
  answerOptions: Array<QuestionAnswerProps>;
}

interface QuestionRangeMapping {
  // starting from this value
  value: number;
  // show this label
  label: string;
}

export interface QuestionRangeProps extends QuestionItemBaseProps {
  // range: QuestionRange;
  min: number;
  max: number;
  step: number;
  value?: number;
  mapping?: QuestionRangeMapping[];
}

export interface QuestionProps {
  children?: React.ReactChildren;
  grid?: "default" | "columns" | "stack";
  item: QuestionItemProps | QuestionRangeProps;
  onChange: (value: any) => void;
  type?: "radio" | "range" | "slot";
}

const Question: React.FC<QuestionProps> = ({
  children,
  item,
  grid = "default",
  onChange = () => console.warn("Question: onChange not defined"),
  type = "radio",
}: QuestionProps) => {
  const [hover, setHover] = useState(-1);
  const onChoose = (e: MouseEvent, value: any) => {
    e.stopPropagation();
    if (typeof onChange === "function") {
      onChange(value);
    }
  };
  const getRangeMapping = ({ value, mapping }) => {
    if (typeof value !== "number") return "";

    return mapping
      .sort((a, b) => b.value - a.value) // sort from high to low (value)
      .filter(search => search.value <= value)
      .find(search => value >= search.value ?? 0).label;
  };

  return (
    <div className={getClassName(css, "Question")}>
      <div className={getClassName(css, "Question__wrapper")}>
        <TypographyHeadline
          size="h3"
          className={getClassName(css, "Question__question")}
          tag="h3"
        >
          {item.question}
        </TypographyHeadline>

        <TypographyCopy
          size="big"
          color="grey"
          className={getClassName(css, "Question__question-copy")}
        >
          {item.copy}
        </TypographyCopy>
        {type === "radio" ? (
          <div
            className={classNames(getClassName(css, "Question__answers"), {
              [getClassName(css, "Question__answers--grid-stack") as string]:
                grid === "stack",
            })}
          >
            {item.answerOptions.map((answer, i) => (
              <div
                className={getClassName(css, "Question__answer")}
                onClick={e => onChoose(e, answer.value)}
                onMouseEnter={() => setHover(i)}
                onMouseLeave={() => setHover(-1)}
                key={answer.label}
              >
                <Button
                  className={classNames(
                    getClassName(css, "Question__answer-button"),
                    {
                      [getClassName(buttonCss, "Button--hover") as string]:
                        i === hover,
                    }
                  )}
                  icon={false}
                  key={i}
                  tag="button"
                  type={answer.active ? "primary" : "secondary"}
                  onClick={e => onChoose(e, answer.value)}
                >
                  {answer.label}
                </Button>

                {React.isValidElement(answer.image) ? (
                  <Clone
                    node={answer.image}
                    className={getClassName(css, "Question__answer-img")}
                  />
                ) : null}

                {typeof answer.color === "string" ? (
                  <Swatch color={answer.color} maxSize={160} />
                ) : null}
              </div>
            ))}
          </div>
        ) : null}

        {type === "range" ? (
          <RangeSlider
            min={item.min}
            max={item.max}
            step={item.step}
            value={item.value ?? undefined}
            label={getRangeMapping({
              mapping: item.mapping,
              value: item.value,
            })}
            onChange={e => onChange(parseInt(e.target.value))}
          />
        ) : null}

        {type === "slot" ? children : null}
      </div>
    </div>
  );
};
export default Question;

import React from "react";

import { default as _get } from "lodash/get";

import { getClassName } from "../../../0-electrons/css";
import Clone from "../../../0-electrons/Clone/Clone";
import Button from "../../../1-atoms/Button/Button";
import TypographyHeadline from "../../../1-atoms/TypographyHeadline/TypographyHeadline";
import QuizIntro from "../../../2-molecules/Quiz/Intro/QuizIntro";

import * as css from "./QuizResult.module.scss";

export interface QuizResultTeaserItemProps {
  copy: React.ReactNode;
  image: React.ReactNode;
  href: string;
  label: string;
}
export interface QuizResultTeasersProps {
  headline: string;
  items: Array<QuizResultTeaserItemProps>;
}
export interface QuizResultProps {
  /* The Element/Node to use as the slot for the image */
  introImage: React.ReactElement;
  /* The Big Headline inside of the image */
  introHeadline?: string;
  /* A Slot before the headline */
  headlineBefore?: React.ReactElement;
  /* The result */
  headline: string;
  /** the children to use for copy/text */
  children: React.ReactNode;
  /** Slot to add content */
  slotFooter?: React.ReactElement | React.ReactFragment;
  /* The (product) teaser */
  teasers?: QuizResultTeasersProps;
  /* The (product) teaser */
  teaserBig?: React.ReactNode;
}

const QuizResult: React.FC<QuizResultProps> = ({
  children,
  headlineBefore,
  headline,
  introHeadline,
  introImage,
  teasers,
  slotFooter,
  teaserBig,
}: QuizResultProps) => {
  const teaserItems = _get(teasers, "items", null);
  const hasTeasers = Array.isArray(teaserItems) && teaserItems.length > 0;

  return (
    <div className={getClassName(css, "QuizResult")}>
      <QuizIntro headline={introHeadline} image={introImage} />

      <div className={getClassName(css, "QuizResult__container")}>
        {React.isValidElement(headlineBefore) ? headlineBefore : null}

        <TypographyHeadline
          size="h2"
          className={getClassName(css, "QuizResult__headline")}
        >
          {headline}
        </TypographyHeadline>
        <div className={getClassName(css, "QuizResult__copy")}>
          {/* Copy text */}
          {children}

          {/* Teaser */}
          {hasTeasers ? (
            <>
              <p>{_get(teasers, "headline", null)}</p>

              {teaserItems.map((teaser, index) => (
                <div
                  className={getClassName(css, "QuizResult__teaser")}
                  key={`teaser-${index}`}
                >
                  <div className={getClassName(css, "QuizResult_img")}>
                    {teaser.image}
                  </div>
                  {teaser.copy}

                  <Button
                    className={getClassName(css, "QuizResult__cta")}
                    href={teaser.href}
                    rel="noopener noreferrer"
                    tag="a"
                    target="_blank"
                    type="primary"
                  >
                    {teaser.label}
                  </Button>
                </div>
              ))}
            </>
          ) : null}

          {React.isValidElement(slotFooter) ? slotFooter : null}
        </div>
      </div>
      {React.isValidElement(teaserBig) ? (
        <Clone
          className={getClassName(css, "QuizResult__teaser-big")}
          node={teaserBig}
        />
      ) : null}
    </div>
  );
};

export default QuizResult;

import React from "react";

import { PageProps, graphql, navigate, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";

import { default as _uniqueId } from "lodash/uniqueId";
import { default as _get } from "lodash/get";

import Scorad from "../components/3-organisms/Scorad/Scorad";
import ContentfulBreadcrumbs from "../contentful/ContentfulBreadcrumbs";

import { getUrl } from "../helper/uri";
import { gtmDataLayerPush } from "../tracking/tracking";
import { start, questions, results } from "../skin-quiz/scorad";

const gtmScorad = event => {
  gtmDataLayerPush({ event: "scorad", data: event });
};

export default function Home(props: PageProps): JSX.Element {
  // Gatsby router adds a location.key that changes on each navigate event
  // we can use this to rerender the Scorad test with changing the react key
  // and therefore resetting the components state
  const locationKey = _get(props, "location.key", 0);

  const scoradData = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "skin-quiz/symptoms" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 160, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);
  const symptomImages = _get(scoradData, "allFile.edges", []).map(
    edge => edge.node
  );

  const breadcrumbItems = [
    {
      name: "Hauttest",
      item: getUrl("hauttest"),
    },
  ];

  return (
    <>
      <GatsbySeo
        title="Neurodermitis Hauttest | In welcher Phase befindest du dich?"
        description="Wenn du herausfinden möchtest, in welcher Neurodermitis Phase du dich aktuell befindest oder dir unsicher bist, hilft dir unser Hauttest sicher weiter"
      />
      <ContentfulBreadcrumbs items={breadcrumbItems} />
      <Scorad
        key={locationKey}
        start={start}
        {...questions(symptomImages)}
        results={results}
        onStepChange={event => {
          gtmScorad(event);
        }}
        onResult={({ type, score }) => {
          const currentLocation = _get(props, "location", "");
          const currentHref = `${_get(currentLocation, "pathname", "")}${_get(
            currentLocation,
            "search",
            ""
          )}`;
          const desiredHref = `/hauttest/?type=${type}&score=${score}`;

          if (currentHref !== desiredHref) {
            navigate(desiredHref, {
              replace: true,
            });
          }
        }}
      />
    </>
  );
}

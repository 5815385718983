import React, { useEffect } from "react";

import { IScoradState, TScoradStep } from "./Scorad.state";
import {
  getCurrentStep,
  getPreviousStep,
  getStepState,
  stateAreaToString,
} from "./helpers";

export function useStepChange({
  currentLevel,
  currentScore,
  onResult,
  onStepChange,
  questionOrder,
  state,
  statePersonType,
}: {
  currentLevel: string;
  currentScore: number;
  onResult: any;
  onStepChange: any;
  questionOrder: TScoradStep[];
  state: IScoradState;
  statePersonType: any;
}): void {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

      if (typeof onStepChange === "function") {
        const prevStep = getPreviousStep({ questionOrder, state });
        const prevStepState = getStepState({
          state,
          questionOrder,
          step: prevStep,
        });
        const currentStep = getCurrentStep(state);

        if (prevStep) {
          const event = {
            step: prevStep,
          };
          if (prevStepState) {
            event.state =
              prevStep === "AREA"
                ? stateAreaToString(prevStepState)
                : prevStepState;
          }

          onStepChange(event);
        }
        if (currentStep === "RESULT") {
          onStepChange({
            step: currentStep,
            state: {
              score: currentScore,
              level: currentLevel,
              type: statePersonType,
            },
          });
        }
      }

      if (state.step === "RESULT" && typeof onResult === "function") {
        onResult({ type: statePersonType, score: currentScore });
      }
    }
  }, [state.step]);
}

export function useUrlScore({
  dispatch,
  setUrlParams,
  urlParams,
}: {
  dispatch: any;
  setUrlParams: React.Dispatch<
    React.SetStateAction<{ score: undefined; type: undefined; source: string }>
  >;
  urlParams: { score: undefined; type: undefined; source: string };
}): void {
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      typeof window.URLSearchParams === "function"
    ) {
      const searchParams = new URLSearchParams(window.location.search);
      const params = {
        score: parseFloat(searchParams.get("score")),
        type: searchParams.get("type"),
      };

      if (
        !isNaN(params.score) &&
        (params.type === "adult" || params.type === "child")
      ) {
        setUrlParams({ ...params, source: "url" });
      }
    }
  }, []);

  useEffect(() => {
    if (
      typeof urlParams.score === "number" &&
      typeof urlParams.type === "string" &&
      typeof urlParams.source === "string" &&
      urlParams.source === "url" // we neeed to differentiate between score set via URL or via state
    ) {
      dispatch({ type: "PERSON_TYPE", value: urlParams.type });
      dispatch({ type: "STEP", value: "RESULT" });
    }
  }, [urlParams]);
}

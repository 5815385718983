/* Libraries */
import { default as _set } from "lodash/set";

import { roundDecimal } from "./helpers";

/**
 * Basic Typings
 */

type TScoradPerson = {
  skin?: "light" | "medium" | "dark";
  type?: "adult" | "child";
};

/** Where symptons are */
type TScoradAreaValue = boolean;
type TScoradAreaFrontBack = {
  front: TScoradAreaValue;
  back: TScoradAreaValue;
};
type TScoradArea = {
  anteriorTrunkUpper: TScoradAreaFrontBack;
  anteriorTrunkMiddle: TScoradAreaFrontBack;
  anteriorTrunkLower: TScoradAreaFrontBack;
  armLeftUpper: TScoradAreaFrontBack;
  armLeftMiddle: TScoradAreaFrontBack;
  armLeftLower: TScoradAreaFrontBack;
  armRightUpper: TScoradAreaFrontBack;
  armRightMiddle: TScoradAreaFrontBack;
  armRightLower: TScoradAreaFrontBack;
  genitals: TScoradAreaValue;
  handLeft: TScoradAreaFrontBack;
  handRight: TScoradAreaFrontBack;
  headAndNeck: TScoradAreaFrontBack;
  legLeftUpper: TScoradAreaFrontBack;
  legLeftMiddle: TScoradAreaFrontBack;
  legLeftLower: TScoradAreaFrontBack;
  legRightUpper: TScoradAreaFrontBack;
  legRightMiddle: TScoradAreaFrontBack;
  legRightLower: TScoradAreaFrontBack;
};

/** How severe the symptoms are */
type TScoradIntensityValue = 0 | 1 | 2 | 3;
type TScoradIntensity = {
  // crusting/oozing
  crusting?: TScoradIntensityValue;
  dryness?: TScoradIntensityValue;
  redness?: TScoradIntensityValue;
  scratchMarks?: TScoradIntensityValue;
  skinThickening?: TScoradIntensityValue;
  swelling?: TScoradIntensityValue;
};

/** Subjective Symptoms */
type TScoradSubjectiveValue = number;
type TScoradSubjective = {
  itching?: TScoradSubjectiveValue;
  sleeplessness?: TScoradSubjectiveValue;
};

/**
 * Action & State
 */

export type TScoradStep =
  | "START"
  | "PERSON_TYPE"
  | "PERSON_SKIN"
  | "AREA"
  | "INTENSITY_CRUSTING"
  | "INTENSITY_DRYNESS"
  | "INTENSITY_REDNESS"
  | "INTENSITY_SCRATCH_MARKS"
  | "INTENSITY_SKIN_THICKENING"
  | "INTENSITY_SWELLING"
  | "SUBJECTIVE_ITCHING"
  | "SUBJECTIVE_SLEEPLESSNESS"
  | "RESULT";

type TScoradAction = {
  type:
    | "AREA"
    | "INTENSITY"
    | "PERSON_SKIN"
    | "PERSON_TYPE"
    | "SUBJECTIVE"
    | "STEP";
  value: string | number | TScoradAreaValue;
  area?: string /* only used for AREA type */;
};

export interface IScoradState {
  person: TScoradPerson;
  area: TScoradArea;
  intensity: TScoradIntensity;
  subjective: TScoradSubjective;
  step: TScoradStep;
}

export const initialState: IScoradState = {
  person: {
    type: undefined,
    skin: undefined,
  },
  area: {
    anteriorTrunkUpper: { front: false, back: false },
    anteriorTrunkMiddle: { front: false, back: false },
    anteriorTrunkLower: { front: false, back: false },
    armLeftUpper: { front: false, back: false },
    armLeftMiddle: { front: false, back: false },
    armLeftLower: { front: false, back: false },
    armRightUpper: { front: false, back: false },
    armRightMiddle: { front: false, back: false },
    armRightLower: { front: false, back: false },
    genitals: false,
    handLeft: { front: false, back: false },
    handRight: { front: false, back: false },
    headAndNeck: { front: false, back: false },
    legLeftUpper: { front: false, back: false },
    legLeftMiddle: { front: false, back: false },
    legLeftLower: { front: false, back: false },
    legRightUpper: { front: false, back: false },
    legRightMiddle: { front: false, back: false },
    legRightLower: { front: false, back: false },
  },
  intensity: {
    crusting: undefined,
    dryness: undefined,
    redness: undefined,
    scratchMarks: undefined,
    skinThickening: undefined,
    swelling: undefined,
  },
  subjective: {
    itching: 0,
    sleeplessness: 0,
  },
  step: "START",
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function reducer(state: IScoradState, action: TScoradAction) {
  const newState = state;

  switch (action.type) {
    case "AREA":
      _set(newState, `area.${action.area}`, action.value);
      //   newState.area[action.area] = action.value;
      return { ...newState };
    case "INTENSITY":
      _set(newState, `intensity.${action.area}`, action.value);
      //   newState.area[action.area] = action.value;
      return { ...newState };
    case "PERSON_SKIN":
      return { ...newState, person: { ...state.person, skin: action.value } };
    case "PERSON_TYPE":
      return { ...newState, person: { ...state.person, type: action.value } };
    case "SUBJECTIVE":
      _set(newState, `subjective.${action.area}`, action.value);
      //   newState.area[action.area] = action.value;
      return { ...newState };
    case "STEP":
      return { ...newState, step: action.value };
    default:
      throw new Error();
  }
}

/**
 * Calculate Score
 * based on @url http://scorad.corti.li/
 *
 * @param state The current scorad question/answer state
 * @returns number
 */
export function calculateScore(state: IScoradState) {
  return roundDecimal(
    calculateScoreArea(state) +
      calculateScoreIntensity(state) +
      calculateScoreSubjective(state)
  );
}

function calculateScoreArea(state: IScoradState) {
  // maximum of 20 when all areas are affected
  const areaScoreMax = 20;
  const areaScore = Object.entries(state.area).map(currentArea => {
    let score = 0;
    const [area, result] = currentArea;
    switch (area) {
      case "anteriorTrunkUpper":
      case "anteriorTrunkMiddle":
      case "anteriorTrunkLower":
        score =
          (result.front === true ? 0.18 / 3 : 0) +
          (result.back === true ? 0.18 / 3 : 0);
        break;
      case "armLeftUpper":
      case "armLeftMiddle":
      case "armLeftLower":
      case "armRightUpper":
      case "armRightMiddle":
      case "armRightLower":
        score =
          (result.front === true ? 0.035 / 3 : 0) +
          (result.back === true ? 0.035 / 3 : 0);
        break;
      case "genitals":
        score = result === true ? 0.01 : 0;
        break;
      case "handLeft":
      case "handRight":
        score =
          (result.front === true ? 0.01 : 0) +
          (result.back === true ? 0.01 : 0);
        break;
      case "headAndNeck":
        score =
          (result.front === true ? 0.045 : 0) +
          (result.back === true ? 0.045 : 0);
        break;
      case "legLeftUpper":
      case "legLeftMiddle":
      case "legLeftLower":
      case "legRightUpper":
      case "legRightMiddle":
      case "legRightLower":
        score =
          (result.front === true ? 0.09 / 3 : 0) +
          (result.back === true ? 0.09 / 3 : 0);
        break;
    }

    // console.log("calculate score", area, result, score, { currentArea });
    return score;
  });

  const areaScoreTotal = areaScore.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );
  const areaScoreScorad = Math.round(areaScoreTotal * areaScoreMax * 100) / 100;

  return areaScoreScorad;
}

function calculateScoreIntensity(state: IScoradState) {
  const intensityScore = Object.entries(state.intensity).map(
    currentIntensity => {
      let score = 0;
      const [intensity, result] = currentIntensity;

      switch (result) {
        case 1:
          score = 3.5;
          break;
        case 2:
          score = 7;
          break;
        case 3:
          score = 10.5;
          break;
      }

      return score;
    }
  );

  const intensityScoreTotal = intensityScore.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );

  return intensityScoreTotal;
}

function calculateScoreSubjective(state: IScoradState) {
  const subjectiveScore = Object.entries(state.subjective).map(
    currentSubjective => {
      const [subjective, result] = currentSubjective;

      return typeof result === "number" ? result : 0;
    }
  );

  const subjectiveScoreTotal = subjectiveScore.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );

  return subjectiveScoreTotal;
}

// TODO: add min/max check for score
import React, { useReducer, useState } from "react";

/* Libraries */
import { default as _get } from "lodash/get";

/* Atomic Design Components and  Helpers */
import { getClassName } from "../../0-electrons/css";
import { Button } from "../../1-atoms/Button/Button";
import Body from "../../1-atoms/Body/Body";
import Question from "../../2-molecules/Question/Question";
import RangeSlider from "../../2-molecules/RangeSlider/RangeSlider";
import QuizStart from "../../3-organisms/Quiz/Start/QuizStart";
import QuizResult from "../../3-organisms/Quiz/Result/QuizResult";

/* Module imports: Styles, State, Helpers, Assets */
import {
  calculateScore,
  initialState,
  reducer,
  IScoradState,
  TScoradStep,
} from "./Scorad.state";
import { getNextStep, getPreviousStep, hasCurrentStepState } from "./helpers";
import * as css from "./Scorad.module.scss";
import { useUrlScore, useStepChange } from "./hooks";

export interface ScoradProps {}

const Scorad: React.FC<ScoradProps> = ({
  start,
  personType,
  personSkin,
  area,
  intensityDryness,
  intensityCrusting,
  intensityRedness,
  intensitySwelling,
  intensityScratchMarks,
  intensitySkinThickening,
  subjectiveItching,
  subjectiveSleeplessness,
  results,
  onResult,
  onStepChange,
}) => {
  const [state, dispatch]: [IScoradState, any] = useReducer(
    reducer,
    initialState
  );
  /**
   * State
   */
  const [resultColor, setResultColor] = useState(undefined);
  const [urlParams, setUrlParams] = useState({
    score: undefined,
    type: undefined,
    source: "state",
  });

  /**
   * Settings and derived variables
   */

  const questionOrder: TScoradStep[] = [
    "START",
    "PERSON_TYPE",
    "PERSON_SKIN",
    "AREA",
    "INTENSITY_DRYNESS",
    "INTENSITY_CRUSTING",
    "INTENSITY_REDNESS",
    "INTENSITY_SCRATCH_MARKS",
    "INTENSITY_SKIN_THICKENING",
    "INTENSITY_SWELLING",
    "SUBJECTIVE_ITCHING",
    "SUBJECTIVE_SLEEPLESSNESS",
    "RESULT",
  ];

  const isQuestion = !state.step.match(/START|RESULT/);
  const currentScore = urlParams.score || calculateScore(state);
  const currentLevel =
    currentScore >= 50 ? "level_2" : currentScore >= 25 ? "level_1" : "level_0";
  const currentStep = getPreviousStep({ questionOrder, state });
  const nextStep = getNextStep({ questionOrder, state });

  const currentStepStateHasValue = hasCurrentStepState({
    questionOrder,
    state,
  });
  const statePersonType = _get(state, "person.type", "adult");

  /**
   * Dispatchers
   */
  const dispatchPrevStep = () => {
    dispatch({
      type: "STEP",
      value: currentStep,
    });
  };
  const dispatchNextStep = () => {
    dispatch({
      type: "STEP",
      value: nextStep,
    });
  };
  const dispatchWithNextStep = reducer => {
    dispatchNextStep();
    dispatch(reducer);
  };

  /**
   * Effects & Hooks
   */

  // check if preset score and type is set in URL to switch to result
  useUrlScore({ dispatch, setUrlParams, urlParams });

  /** Listen to step changes */
  useStepChange({
    currentLevel,
    currentScore,
    onResult,
    onStepChange,
    questionOrder,
    state,
    statePersonType,
  });

  return (
    <div className={getClassName(css, "Scorad")}>
      <div className={getClassName(css, "Scorad__inner")}>
        {state.step === "START" ? (
          <QuizStart
            copy={start.copy}
            headline={start.headline}
            introHeadline={start.introHeadline}
            introImage={start.introImage}
            onStart={() => dispatch({ type: "STEP", value: "PERSON_TYPE" })}
            startLabel={start.startLabel}
          />
        ) : null}

        {state.step === "PERSON_TYPE" ? (
          <Question
            grid="stack"
            item={{
              ...personType,
              answerOptions: personType.answerOptions.map(option => {
                return {
                  ...option,
                  active: option.value === state.person.type,
                };
              }),
            }}
            onChange={value => {
              dispatchWithNextStep({ type: "PERSON_TYPE", value });
            }}
          />
        ) : null}

        {state.step === "PERSON_SKIN" ? (
          <Question
            // grid="stack"
            item={{
              ...personSkin,
              answerOptions: personSkin.answerOptions.map(option => {
                return {
                  ...option,
                  active: option.value === state.person.skin,
                };
              }),
            }}
            onChange={value => {
              dispatchWithNextStep({ type: "PERSON_SKIN", value });
            }}
          />
        ) : null}

        {state.step === "AREA" ? (
          <div>
            <Question
              type="slot"
              item={{
                ...area,
                copy: area.copy[statePersonType],
              }}
            >
              <Body
                type={statePersonType}
                active={_get(state, "area")}
                onClick={area =>
                  dispatch({
                    type: "AREA",
                    area: area,
                    value: !_get(state, `area.${area}`, false),
                  })
                }
              />
            </Question>
          </div>
        ) : null}

        {state.step === "INTENSITY_CRUSTING" ? (
          <Question
            item={{
              ...intensityCrusting,
              answerOptions: intensityCrusting.answerOptions.map(option => {
                return {
                  ...option,
                  image: _get(option, `image.${state.person.skin}`, undefined),
                  active: option.value === state.intensity.crusting,
                };
              }),
            }}
            onChange={value => {
              dispatchWithNextStep({
                type: "INTENSITY",
                area: "crusting",
                value,
              });
            }}
          />
        ) : null}

        {state.step === "INTENSITY_DRYNESS" ? (
          <Question
            item={{
              ...intensityDryness,
              answerOptions: intensityDryness.answerOptions.map(option => {
                return {
                  ...option,
                  image: _get(option, `image.${state.person.skin}`, undefined),
                  active: option.value === state.intensity.dryness,
                };
              }),
            }}
            onChange={value => {
              dispatchWithNextStep({
                type: "INTENSITY",
                area: "dryness",
                value,
              });
            }}
          />
        ) : null}

        {state.step === "INTENSITY_REDNESS" ? (
          <Question
            item={{
              ...intensityRedness,
              answerOptions: intensityRedness.answerOptions.map(option => {
                return {
                  ...option,
                  image: _get(option, `image.${state.person.skin}`, undefined),
                  active: option.value === state.intensity.redness,
                };
              }),
            }}
            onChange={value => {
              dispatchWithNextStep({
                type: "INTENSITY",
                area: "redness",
                value,
              });
            }}
          />
        ) : null}

        {state.step === "INTENSITY_SCRATCH_MARKS" ? (
          <Question
            item={{
              ...intensityScratchMarks,
              answerOptions: intensityScratchMarks.answerOptions.map(option => {
                return {
                  ...option,
                  image: _get(option, `image.${state.person.skin}`, undefined),
                  active: option.value === state.intensity.scratchMarks,
                };
              }),
            }}
            onChange={value => {
              dispatchWithNextStep({
                type: "INTENSITY",
                area: "scratchMarks",
                value,
              });
            }}
          />
        ) : null}

        {state.step === "INTENSITY_SKIN_THICKENING" ? (
          <Question
            item={{
              ...intensitySkinThickening,
              answerOptions: intensitySkinThickening.answerOptions.map(
                option => {
                  return {
                    ...option,
                    image: _get(
                      option,
                      `image.${state.person.skin}`,
                      undefined
                    ),
                    active: option.value === state.intensity.skinThickening,
                  };
                }
              ),
            }}
            onChange={value => {
              dispatchWithNextStep({
                type: "INTENSITY",
                area: "skinThickening",
                value,
              });
            }}
          />
        ) : null}

        {state.step === "INTENSITY_SWELLING" ? (
          <Question
            item={{
              ...intensitySwelling,
              answerOptions: intensitySwelling.answerOptions.map(option => {
                return {
                  ...option,
                  image: _get(option, `image.${state.person.skin}`, undefined),
                  active: option.value === state.intensity.swelling,
                };
              }),
            }}
            onChange={value => {
              dispatchWithNextStep({
                type: "INTENSITY",
                area: "swelling",
                value,
              });
            }}
          />
        ) : null}

        {state.step === "SUBJECTIVE_ITCHING" ? (
          <Question
            type={subjectiveItching.type}
            item={{
              ...subjectiveItching,
              value: _get(state, "subjective.itching", undefined),
            }}
            onChange={value => {
              dispatch({
                type: "SUBJECTIVE",
                area: "itching",
                value,
              });
            }}
          />
        ) : null}

        {state.step === "SUBJECTIVE_SLEEPLESSNESS" ? (
          <Question
            type={subjectiveSleeplessness.type}
            item={{
              ...subjectiveSleeplessness,
              value: _get(state, "subjective.sleeplessness", undefined),
            }}
            onChange={value => {
              dispatch({
                type: "SUBJECTIVE",
                area: "sleeplessness",
                value,
              });
            }}
          />
        ) : null}

        {isQuestion ? (
          <div className={getClassName(css, "Scorad__nav")}>
            <Button
              onClick={dispatchPrevStep}
              icon="directionLeft"
              iconOnly
              type="secondary"
            />

            <Button
              // TODO: disable if current value not set yet
              disabled={!currentStepStateHasValue}
              onClick={
                currentStepStateHasValue
                  ? nextStep === "RESULT"
                    ? () => {
                        onResult({
                          type: statePersonType,
                          score: currentScore,
                        });
                      }
                    : dispatchNextStep
                  : undefined
              }
              icon="directionRight"
              iconOnly
              type="secondary"
            />
          </div>
        ) : null}

        {state.step === "RESULT" ? (
          <QuizResult
            headlineBefore={
              <RangeSlider
                disabled
                description="Dieses Testergebnis basiert auf dem klinischen Bewertungssystem SCORAD (SCORing Atopic Dermatitis)."
                min={0}
                max={103}
                step={1}
                value={currentScore}
                label={
                  currentScore >= 50
                    ? "Schwer"
                    : currentScore >= 25
                    ? "Moderat"
                    : "Mild"
                }
                onColorChange={color => setResultColor(color)}
              />
            }
            headline={results[currentLevel].headline}
            introHeadline={results.introHeadline}
            introImage={results.introImage}
            teasers={results[currentLevel].teasers}
            slotFooter={
              typeof results[currentLevel].slotFooter === "function"
                ? results[currentLevel].slotFooter({
                    personType: statePersonType,
                    resultColor,
                    resultScore: currentScore,
                  })
                : undefined
            }
            teaserBig={results[currentLevel].teaserBig}
          >
            {results[currentLevel].children({
              personType: statePersonType,
            })}
          </QuizResult>
        ) : null}
      </div>
    </div>
  );
};

export default Scorad;

import { default as _camelCase } from "lodash/camelCase";
import { default as _get } from "lodash/get";
import { default as _isPlainObject } from "lodash/isPlainObject";
import { default as _upperFirst } from "lodash/upperFirst";

import { IScoradState, TScoradStep } from "./Scorad.state";

export function getCurrentStep(state) {
  return _get(state, "step", undefined);
}

export function getCurrentStepState({
  questionOrder,
  state,
}: {
  questionOrder: TScoradStep[];
  state: IScoradState;
}): any {
  const currentStepPath = getStepPath({
    desiredStep: _get(state, "step", undefined),
    questionOrder,
  });
  const currentStepState = _get(state, currentStepPath, undefined);

  return currentStepState;
}

export function getNextStep({
  questionOrder,
  state,
}: {
  questionOrder: TScoradStep[];
  state: IScoradState;
}) {
  return questionOrder[questionOrder.indexOf(state.step) + 1];
}

export function getPreviousStep({
  questionOrder,
  state,
}: {
  questionOrder: TScoradStep[];
  state: IScoradState;
}) {
  return questionOrder[questionOrder.indexOf(state.step) - 1];
}

export function getStepState({
  state,
  questionOrder,
  step,
}: {
  state: IScoradState;
  questionOrder;
  step;
}): any {
  const stepPath = getStepPath({ desiredStep: step, questionOrder });
  const currentStepState = _get(state, stepPath, undefined);

  return currentStepState;
}

export function getStepPath({
  desiredStep,
  questionOrder,
}: {
  desiredStep: TScoradStep;
  questionOrder: TScoradStep[];
}): string {
  const stepStateMatching = {};
  questionOrder.forEach(step => {
    if (step === "AREA") stepStateMatching[step] = "area";
    if (step.match("PERSON")) {
      stepStateMatching[step] = `person.${_camelCase(
        step.split("PERSON_")[1]
      )}`;
    }
    if (step.match("INTENSITY")) {
      stepStateMatching[step] = `intensity.${_camelCase(
        step.split("INTENSITY_")[1]
      )}`;
    }
    if (step.match("SUBJECTIVE")) {
      stepStateMatching[step] = `subjective.${_camelCase(
        step.split("SUBJECTIVE_")[1]
      )}`;
    }
  });

  const currentStepStatePath = stepStateMatching[desiredStep];
  return currentStepStatePath;
}

export function hasCurrentStepState({
  questionOrder,
  state,
}: {
  questionOrder: TScoradStep[];
  state: IScoradState;
}): boolean {
  const stepState = getCurrentStepState({
    questionOrder,
    state,
  });

  if (typeof stepState === "number") return true;

  return stepState;
}

export function stateAreaFlatten(object) {
  const flattened = {};
  const entries = Object.entries(object);
  entries.forEach(entry => {
    const [path, value] = entry;

    if (_isPlainObject(value)) {
      Object.entries(value).forEach(deepEntry => {
        const [deepPath, deepValue] = deepEntry;

        flattened[`${path}${_upperFirst(deepPath)}`] = deepValue;
      });
    } else if (typeof value === "boolean") {
      flattened[path] = value;
    }
  });

  return flattened;
}

export function stateAreaToString(areaState) {
  return (
    Object.entries(stateAreaFlatten(areaState))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([path, value]) => value === true)
      .map(([path]) => path)
      .sort((a, b) => a - b)
      .join(", ")
  );
}

export function roundDecimal(number, decimals = 2) {
  let sign = number < 0 ? -1 : 1;
  number = Math.abs(number);
  return (
    sign *
    Number(Math.round(number.toFixed(10) + "e" + decimals) + "e-" + decimals)
  );
}

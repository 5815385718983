import React, { useState } from "react";

import { getClassName } from "../../../0-electrons/css";
import Clone from "../../../0-electrons/Clone/Clone";
import TypographyHeadline from "../../../1-atoms/TypographyHeadline/TypographyHeadline";

import * as css from "./QuizIntro.module.scss";

export interface QuizIntroProps {
  /* The QuizIntro headline */
  headline?: React.ReactNode;
  /* The QuizIntro image */
  image: React.ReactElement;
}

export const QuizIntro: React.FC<QuizIntroProps> = ({
  image,
  headline,
}: QuizIntroProps) => {
  const hasImage = React.isValidElement(image);
  const hasHeadline = typeof headline === "string" && headline.length;

  if (!hasImage) {
    console.log("QuizIntro: please provide an image");
    return null;
  }

  return (
    <div className={getClassName(css, "QuizIntro")}>
      <Clone node={image} className={getClassName(css, "QuizIntro__image")} />

      {hasHeadline ? (
        <TypographyHeadline
          color="white"
          className={getClassName(css, "QuizIntro__headline")}
        >
          {headline}
        </TypographyHeadline>
      ) : null}
    </div>
  );
};

export default QuizIntro;

import React from "react";

import classNames from "classnames";

import { getClassName } from "../../0-electrons/css";
import * as css from "./Swatch.module.scss";

export interface ISwatchProps {
  color: string;
  maxSize?: number;
  className?: string;
}

const Swatch: React.FC<ISwatchProps> = ({
  color,
  className,
  maxSize,
}: ISwatchProps) => {
  const hasSize = typeof maxSize === "number" && maxSize > 0;
  const maxSizeValue = hasSize ? maxSize : undefined;
  return (
    <div
      className={classNames(getClassName(css, "Swatch"), {
        [className as string]:
          typeof className === "string" && className.length > 0,
      })}
      style={{
        background: color,
        width: maxSizeValue,
        height: hasSize ? 0 : undefined,
        paddingBottom: hasSize ? "100%" : undefined,
        maxWidth: hasSize ? "100%" : undefined,
        maxHeight: hasSize ? "100%" : undefined,
      }}
    />
  );
};

export default Swatch;

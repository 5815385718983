// TODO: outsource base RangeSlider to atoms
import React, { useRef, useEffect, useState } from "react";

import classNames from "classnames";
import gradientParser from "gradient-parser";
import _get from "lodash/get";

import { getClassName } from "../../0-electrons/css";

import * as css from "./RangeSlider.module.scss";

import { LinearGradientHelper } from "./helper";

export interface RangeSliderProps {
  description?: string;
  disabled?: boolean;
  label?: string;
  max?: number;
  min?: number;
  step?: number;
  value?: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onColorChange?: (color: string) => void;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  description,
  disabled = false,
  label,
  max = 10,
  min = 0,
  step = 1,
  value = 0,
  onChange = e => console.warn("RangeSlider: onChange event not set", e),
  onColorChange,
}) => {
  /**
   * Derived and calculated values
   */
  const isSSR = typeof window === "undefined";
  const fraction = value / max;
  const percent = Math.round(fraction * 100);
  const stateOffset = Math.round(25 * fraction);

  /**
   * Refs and State
   */
  const gradientRef = useRef();
  const [gradientHelper, setGradientHelper] = useState(undefined);
  const [currentColor, setCurrentColor] = useState(undefined);

  /**
   * Effect: Get current range gradient color
   */
  useEffect(() => {
    const gradientStyle = getComputedStyle(gradientRef.current);
    const backgroundImage = gradientStyle.backgroundImage;
    const gradientParsed = gradientParser.parse(backgroundImage);

    const gradientStopCount = gradientParsed[0].colorStops.length;
    const gradientStops = gradientParsed[0].colorStops.map((stop, i) => {
      // add fallbacks for stopPosition, can get lost in CSS optimization
      const stopPosition = _get(stop, "length.value", i === 0 ? 0 : 100);
      return [`rgb(${stop.value.join(",")})`, stopPosition / 100];
    });

    setGradientHelper(new LinearGradientHelper(gradientStops));
  }, [gradientRef]);
  /**
   * Effect: Update current color
   */
  useEffect(() => {
    const color = gradientHelper ? gradientHelper.getColor(percent) : null;

    if (typeof onColorChange === "function") {
      onColorChange(color);
    }

    setCurrentColor(color);
  }, [gradientHelper, percent]);

  return (
    <div
      className={classNames(getClassName(css, "RangeSlider"), {
        [getClassName(css, "RangeSlider--disabled") as string]: disabled,
      })}
    >
      <div className={getClassName(css, "RangeSlider__ui")}>
        <div
          className={getClassName(css, "RangeSlider__state")}
          style={{ left: `calc(${percent}% - ${stateOffset}px)` }}
        >
          <span
            className={getClassName(css, "RangeSlider__value")}
            style={{ borderColor: currentColor }}
          >
            {value}
          </span>
          <span className={getClassName(css, "RangeSlider__label")}>
            {label}
          </span>
        </div>
        <input
          type="range"
          disabled={disabled}
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={onChange}
          className={getClassName(css, "RangeSlider__range")}
        />
        <div
          className={getClassName(css, "RangeSlider__gradient")}
          ref={gradientRef}
        />
        <div className={getClassName(css, "RangeSlider__min")}>{min}</div>
        <div className={getClassName(css, "RangeSlider__max")}>{max}</div>
      </div>

      {typeof description === "string" && description.length > 0 ? (
        <span className={getClassName(css, "RangeSlider__description")}>
          {description}
        </span>
      ) : null}
    </div>
  );
};

export default RangeSlider;
